.container-of-workshop {
        font-size: 1.2rem;
        width: 98%;
        margin: 1% auto;
        padding: 2%;
        text-align: justify;
        line-height: 2;
        box-shadow: 0 0 34px 7px gray;
        border-radius: 5px;
        transition: all .4s ease;
     
        /* background-color: rgba(0,0,0, 0.5) */
    }
    
.container-of-workshop:hover{
        box-shadow: 0 0 44px 15px gray;
        border-radius: 5px;
      background: none;
}

.workshop-gallery-container{
        display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.workshop-gallery-container img{
        width: 45%;
        height: 60vh;
        margin: 1%;
        transition: all .7s ease;
        box-shadow: 0 0 6px 2px gray;
}
.workshop-gallery-container img:hover{
        width: 47%;
        height: 61vh;
       box-shadow: 0 0 15px 4px gray;
}
