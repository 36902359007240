*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}



.previousQuestionPapers-link-box-container{
    text-align: center;
    width: 60%;
    margin: auto;
    font-size: 1.2rem;
    line-height: 30px;
}
.previousQuestionPapers-link-box-container ul{
    list-style: none;
    /* border: 1px solid red; */
}
.previousQuestionPapers-link-box-container ul li{
   font-weight: bold;
}
.previousQuestionPapers-link-box-container ul li a{
   text-decoration: none;
   color: black;
   padding: 2px 36px;
   text-decoration: underline;
}
.previousQuestionPapers-link-box-container ul li a:hover{
   background-image: linear-gradient(180deg, transparent, rgb(203, 203, 203));
   border-bottom: 1px solid;

}
.pre-phd-pre-que-first-a:hover{
   background-image: linear-gradient(180deg, transparent, rgb(203, 203, 203));
   border-bottom: 1px solid;

}
.previousQuestionPapers-link-box-container ul li ul {
    font-weight: lighter;
    position: relative;
    left: 5px;
    color: rgb(77, 76, 76);
    margin-bottom: 3%;
    font-size: 1rem;
}

.previousQuestionPapers-link-box-container-heading {
    background-color: #010101;
    margin: 1% auto;
    width: 60%;
    color: #fbbe31;
    font-size: 1.3rem;
    font-weight: bold;
    box-shadow: 4px 4px 4px grey;
    margin-top: 6%;
}
.previousQuestionPapers-link-box-container-sub-heading{
    background-image: linear-gradient(180deg, transparent,rgb(244,244,244));
    margin: 1% auto;
    border-bottom: 1px solid rgb(211, 210, 210);
    width: 40%;
    color: blue;
    font-size: 1.3rem;
    font-weight: bold;
}

/* MediaQuery */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .PreviousQuestionPapers-sub-heading {
        font-size: 1.1rem;
        width: 84%;
    }

    .previousQuestionPapers-link-box-container {
        text-align: center;
        width: 90%;
        margin: auto;
        font-size: 1rem;
        line-height: 30px;
    }
    .previousQuestionPapers-link-box-container-heading {
        width: 70%;
        font-size: 1.1rem;
        
    }
    .previousQuestionPapers-link-box-container ul li {
        font-weight: bold;
        width: 100%;
    }
}