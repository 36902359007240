.rd-project-table-container{
    width: 100%;
    margin: 3% 0;
}
.rd-project-table-container table{
    width: 90%;
    margin: 3% auto;
}

.table-head-of-project-data{
    text-align: center;
    font-weight: bold;
}
.rd-project-second-heading{
    text-align: center;
    background-color: green;
    padding: 6px 0;
    /* width: 80%; */
    margin: 2% auto;
    color: #e3b408;
    background: linear-gradient(45deg, transparent,rgba(194, 193, 193, 0.741), black, rgba(194, 193, 193, 0.741), transparent);
    font-size: 1.4rem;
}
.rd-project-second-ul-container{
    width: 80%;
    margin: auto;
}
.rd-project-second-ul-container li{
    margin: 3% 0;
    font-size: 1.1em;
    line-height: 27px;
}
