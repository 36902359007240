*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.scholorship-component{
        width: 85%;
        margin: auto;
        margin-bottom: 2%;
}

.scholorship-main-heading {
    text-align: center;
    color: white;
    margin: 2% auto;
    padding: 6px 0;
    background: linear-gradient(45deg, black, transparent);
    background: radial-gradient( circle at center, #000000 0%, #858585 50%, #ffffff 100% );
    font-size: 1.4rem;
}

.scholorship-data-container p{
        margin: 1.5% 4%;
        font-size: 1.1rem;
        font-weight: bold;
}
.scholorship-data-container p a{
    color: black;
    text-decoration: none;
    position: relative;
}
/* .scholorship-data-container p a:hover{
    color: rgb(255, 0, 0);
  
} */

  /* .scholorship-data-container p a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ff0000;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .scholorship-data-container p a:hover::before {
    visibility: visible;
    width: 100%;
  } */


  /* sec2 */
.scholorship-data-container ul li{
        margin: 1.5% 4%;
        font-size: 1.1rem;
        font-weight: bold;
}
.scholorship-data-container ul li a{
    color: black;
    text-decoration: none;
    position: relative;
}
/* .scholorship-data-container p a:hover{
    color: rgb(255, 0, 0);
  
} */

  .scholorship-data-container ul li a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ff0000;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .scholorship-data-container ul li a:hover::before {
    visibility: visible;
    width: 100%;
  }

 .scholorship-details-heading {
    text-align: center;
    color: red;
    margin: 3%;
    font-size: 1.8rem;
    letter-spacing: 12.1px;
    text-decoration: underline;
    font-weight: bold;
}