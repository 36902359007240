.governing-act-vision-mision-second-heading{
    color: red;
}

.governing-acts-vision-mision-data-container-ul{
    width: 80%;
    margin:3% auto;
}
.governing-acts-vision-mision-data-container-ul li{
  margin: 1.5% 0;
}
.governing-acts-vision-mision-data-container-ul li a ,.governing-acts-vision-mision-data-container-ul li button{
 font-weight: bold;
 text-decoration: none;
 color: black;
 font-size: 1.2rem;
 transition: all .2s ease;
 border: none;
 background: none;
}
.governing-acts-vision-mision-data-container-ul li a:hover ,.governing-acts-vision-mision-data-container-ul li button:hover{
color: red;
}
.governing-acts-vision-mision-data-container-ul li::marker{
color: rgb(255, 0, 0);

}