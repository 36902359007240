.redesign-navigation-container{
  background-color: rgb(205, 202, 202);

}
.redesign-navigation-ul{
  list-style: none;
  display: flex;
justify-content: space-evenly;
}

.redesign-navigation-li{
  font-weight: bold;
  padding: 13px 10px;
}
.redesign-navigation-li a{
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
  padding: 3px 12px;
}