:root {
  --navBg: #003366;
}

.side-nav-full-comp {
  /* height: 100vh; */
  position: fixed;
  top: 0;
  z-index: 999;
  animation: hideMenu 3s;
  animation-timing-function: ease-in-out;  
}

.s-navbar {
  height: 60px;
  width: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.7rem;
  background: none;
}

.nav-menu {
  display: flex;
  justify-content: center;
  position: relative;
  top: 20;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  
  /* background-color: green; */
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 8px 0 8px 16px; */
  list-style: none;
  height: 40px;
}

.map-data {
  /* background-image: linear-gradient(to right, #026cddaf, rgba(9, 0, 0, 0.449)); */
  /* background-color: #003366d6;
  border: 2px solid #003366; */
  border-radius: 13px;
  height: 94vh;
  margin-left: 2%;
  margin-top: 15px;
  position: relative;
  animation: showMenu 1s;
  animation-timing-function: ease-in-out;
}

/* sidenav animation css new on 14 march */
.sidebar-animation-effect-example-new {
  position: relative;
  animation: showMenu 0.8s;
  animation-timing-function: ease; 
  background-color: #003366e4;
  border: 1px solid #003366;
  backdrop-filter: blur(10px);
  
}



/* sidenav animation css new on 14 march */

.nav-text a {
  text-decoration: none;
  /* color: var(--fontColor); */
  color: white;
  font-size: 14px;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px;
  border-radius: 4px;

  
}

.nav-text a:hover {
  /* background-color: #1a83ff; */
  background-color: #f0c000ea;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: linear;
  /* padding: 0 16px; */
  width: 100%;
  color: black;

}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.toggle-btn-box {
  background-color: rgb(255, 251, 0);
}

.navbar-toggle {
  background-color: var(--navBg);
  width: 100%;
  /* height: 80px; */
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.sidenav-toggle-btn {
  position: fixed;
  border: none;
  background: none;
  bottom: 48%;
  background-color: #181818;
  padding: 7px;
  backdrop-filter: blur(22px);
  color: #ffca07;
  text-shadow: 2px 2px 2px black;
  box-shadow: 1px 1px 2px 0px rgb(114, 113, 113);
  border-radius: 12px;
  animation: showMenu 1.4s;
  animation-timing-function: ease-in-out;
  transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
  
   /* writing-mode: vertical-rl; */
   text-orientation: upright;


}

@keyframes showMenu {
  from {
    left: -100%;
  }

  to {
    left: 0%;
  }
}
@keyframes hideMenu {
  from {
    right: 100%;
  }

  to {
    left: -0%;
  }
}

.sidenav-toggle-button-class-for-responsive-data {
  font-size: 2.3rem;
  /* color: #fff; */
}

@media only screen and (max-width: 480px) and (min-width: 320px) {

  .sidenav-toggle-btn {

    background: #f2ab29;

    border-radius: 78px;
    font-size: 8px;

  }


  .sidenav-toggle-button-class-for-responsive-data {
    font-size: 1.8rem;
    color: black;
  }
}