.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup-content h1 {
    margin-top: 0;
  }
  
  .popup-content button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  