.visionforviksitBharat-container-for-img-and-video{
    text-transform: none;
    width: 99%;
    margin: auto;
    display: flex;
}
.visionforviksitBharat-container ul li{
    font-size: 1.2rem;
    margin: 3% auto;
    line-height: 30px;
}
.btn-container-of-viksit-bharat {
    display: flex;
}

.viksit-bharat-feedback-btn {
    background: #2c498d;
    border-radius: 7px;
    color: #fff;
    font-size: 1.2rem;
    margin: 2% auto;
    padding: 10px 25px;
    text-decoration: none;
    width: 23%;
    text-align: center;
}

.social-media-container-of-viksit-bharat {
    display: flex;
    font-size: 2.3rem;
    justify-content: flex-end;
    margin-top: 18px;
    text-align: center;
    align-items: center;
}


.social-media-container-of-viksit-bharat a {
    color: #000;
    margin: 0 10px;
    transition: all .2s ease;
}

.social-media-container-of-viksit-bharat a:hover {
    color: #5e5e5e;
}

.vikist-bharat-banner-container {
    text-align: center;
    width: 45%;
    margin: auto;
}
.viksit-bharat-video-container {
    width: 45%;
    margin: auto;
}
.viksit-bharat-video-container Iframe{
    height: 547px;
    width: 100%;
    text-align: center;
}

.viksitbharat-text-heading{
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.vikist-bharat-banner-container img {
    height: 600px;
    margin: auto;
    width: 100%;
    position: relative;
    top: -30px;
}

.pmIndiaWebcastLink-viksit-bharat-link-container{
    font-size: 1.3rem;
    font-weight: bold;
}
.pmIndiaWebcastLink-viksit-bharat-link-container a{
    color: #0b6dcf;
    transition: all .2s ease;
}
.pmIndiaWebcastLink-viksit-bharat-link-container a:hover{
    color: #fa0505;
    
}

.viksit-bharatonly-btn-container-like-side-menu{
width: 30%;
/* margin-top: 2%; */
}
.viksit-bharatonly-btn-container-like-side-menu section{
width: 100%;
margin: 3% auto;
}
.vision-for-viksit-bharat-btn-for-side-navigation-link {
    display: block;
    padding: 19px 20px;
    width: 85%;
    display: flex;
    justify-content: space-between;
    
}
.red-color-in-btn {
    /* background-color: red; */
    color: var(--fontColor);
    box-shadow: 3px 6p;
    font-size: 1.2rem;
    width: 3%;
    display: block;
    text-align: right;
    position: relative;
   
}
.vision-for-viksit-bharat-btn-for-side-navigation-link:hover {
   .red-color-in-btn{
color: white;
left: 6px;
   }
}


.vision-for-viksit-bharat-btn-for-side-navigation-link:active,
.vision-for-viksit-bharat-btn-for-side-navigation-link:focus
 {
    color: var(--fontColor);
}


@media only screen and (max-width: 600px){
.btn-container-of-viksit-bharat {
    flex-direction: column;
}
.viksit-bharatonly-btn-container-like-side-menu {
    width: 93%;
    margin: auto;
    /* margin-top: 2%; */
}
.banner-container img {
    height: 200px;
    margin: auto;
    width: 90%;
}
.viksit-bharat-feedback-btn {
    background: #2c498d;
    border-radius: 7px;
    color: #fff;
    font-size: 1.2rem;
    margin: 2% auto;
    padding: 10px 25px;
    text-decoration: none;
    width: 97%;
    text-align: center;
}
.visionforviksitBharat-container-for-img-and-video {
    text-transform: none;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.vikist-bharat-banner-container img {
    height: 251px;
    margin: auto;
    width: 100%;
    position: relative;
    top: 0;
}
.vikist-bharat-banner-container {
    text-align: center;
    width: 99%;
    margin: auto;
}
.viksit-bharat-video-container {
    width: 99%;
    margin: auto;
    margin-top: 12px;
}
.viksit-bharat-video-container Iframe {
    height: 256px;
    width: 100%;
    text-align: center;
}
}