.vice-chancellor-component-dpsru{
    width: 100%;
    font-family: "Poppins", sans-serif;
}

.vc-sir-image-and-details-container{
    width: 70%;
    margin: auto;
    /* border: 1px solid red; */
    padding: 2% 0;
    margin: 3% auto;
    /* background-color: #c9eafc; */
    border-radius: 10px;
    /* box-shadow: 3px 3px 3px #46474762; */
    box-shadow: -1px 0px 28px #47464699;
    margin-bottom: 0;

}
.vc-sir-image-and-details-container a{
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}

.vc-sir-info-container {
text-align: center;
padding: 2% 0;
font-family: "Poppins", sans-serif;

}
img.rounded-corners {
    border-radius: 20px;
}

img.rounded-corners {
    border-radius: 20px;
}
.vc-sir-img {
    background-color: white;
    border-radius: 10px;
    width: 23%;
    margin-right: 10%;
    margin-left: 3%;
    padding: 2%;
    box-shadow: inset 3px 3px 3px #46474762, 3px 3px 3px #46474762;
}


.vc-sir-page-buttons {
    margin: auto;
    text-align: center;
}
.vc-buttons {
    background-color: #041d54cb;
    color: rgb(211, 211, 211);
    font-size: 20px;
    padding: 1.5% 9%;
    font-weight: 600;
    margin: auto 0.5%;
    border: none;
    border-radius: 0 0 10px 10px;
    transition: all .4s ease;
}
.vc-buttons:hover {
    background-color: #0a3081dc;
    color: rgb(255, 255, 255);
 
}

.vc-buttons:active,
.vc-buttons:focus
 {
    background-color: #0a3081dc;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 2px #0c0c0c;
}



.positions-container{
    width: 80%;
    margin: 2% auto;
    background-color: rgb(217, 224, 237);
    padding: 1px 2px;
    padding-bottom: 13px;
    border-radius: 20px;


  
}
.position-table {
    caption-side: bottom;
    border-collapse: collapse;
    box-shadow: -1px 0px 28px #47464699;
    background-color: white;
    
}
.positions-and-award-heading{
    text-align: center;
    margin: 2% 0;
    font-size: 1.5rem;
    color: #ff0000;

}


.position-table tr:nth-child(odd) {
    background-color: #ecf6ff67;
}



.position-table tr:nth-child(even):hover {
    background-color: #ffffff;
}
.position-table tr:nth-child(odd):hover {
    background-color: #ffffff;
}
tr, td {
    box-shadow: 2px 2px 2px #2323233b;
    background-color: rgba(255, 255, 255, 0);
    /* padding: 1% 1%; */
    /* font-family: "Poppins", sans-serif; */
    line-height: 27px;
}

.vc-sir-info-text-vice-chancellor{
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.619);
}
.vc-sir-info-text-vice-chancellor-secendary-text{
    font-size: 18px;
    font-weight: 600;
}
.vc-sir-info-text-vice-chancellor-third-text{
   
        font-size: 15px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.76);
    
}



@media only screen and (max-width: 600px){
    .position-table tr {
       display: flex;
       flex-direction: column;
    }
}