*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.tender-component{
    width: 100%;
}
.tender-data-container{
    width: 80%;
    margin: auto;
}

.tender-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: rgb(33, 33, 195);
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
}

.tender-ol li{
    margin: 16px 0;
}
.tender-ol li a{
    padding: 5px 10px;
    text-decoration: none;
    font-size: 1.3rem;
    color: #333;
    display: flex;
    transition: all .5s ease;
}

.tender-ol li a:hover{
   color: red;
 
 }

 /* MediaQuery */
 @media only screen and (max-width: 480px) and (min-width: 320px) {
    .tender-ol {
        margin-left: 10%;
    }
    .tender-ol li a{
        font-size: 1.1rem;
    }
 }