.pushp-pradarshanam-dpsru-heading {
    background-color: #ffffff;
    box-shadow: 3px 3px 3px 1px #e7e7e7;
    color: #161665;
    font-size: 2rem;
    padding: 6px;
    text-align: center;
    text-shadow: 2px 2px 2px #9c9a9a;
}
.pushp-pradarsanam-date-heading {
    background: none;
    margin: .3% auto;
    position: relative;
    top: -18px;
    color: red;
    font-size: 1.1rem;
}

.pushp-pradarshanam-ol{
    margin: 3% 5%;
    position: relative;
    bottom: 50px;
}

.pushp-pradarshan-galery img{
margin: 1%;
position: relative;
transition: all .4s ease-in-out;
}
.pushp-pradarshan-galery img:hover{
width: 31%;
box-shadow: 0px 0px 11px 5px gray;
}