*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}
.rules-component{
    width: 80%;
    margin: auto;
}

.dpsru-logo-rules{
    width: 15%;
    display: block;
    margin: auto;
}

.rules-paragrauph-heading{
    width: 60%;
    font-weight: bold;
    margin: 2% auto ;
    text-align: center;
    color: blue;
    font-size: 1.3rem;
    line-height: 27px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rules-data-container p a{
    font-size: 1.3rem;
    color: blue;
    text-decoration: none;
    margin: 2% 0;
    padding: 2px 9px;
}
.rules-data-container p a:hover{
    background-color: rgb(52, 52, 202);
    border-radius: 3px;
    color: white;
    text-decoration: underline;
}
.rules-data-container{
    background-image: linear-gradient(180deg, transparent,rgb(247, 247, 247));
    border-bottom:2px solid rgba(240, 238, 238, 0.608);
    width: 60%;
    margin: 2% auto;
    padding-bottom: 12px;
}
.rules-data-container p{
    margin: 2% auto;
    text-align: center;
}

.rules-container-sub-heading{
    text-align: center;
    font-size: 1.3rem;
    margin: 10px auto;
    margin-top: 33px;
    background-color: #3c55af;
    padding: 3px 0;
    width: 40%;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
}


/* Responsive Media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .dpsru-logo-rules {
        width: 47%;
        display: block;
        margin: auto;
    }
    .rules-paragrauph-heading {
        width: 93%;
        font-weight: bold;
        margin: 2% auto;
        text-align: center;
        color: blue;
        font-size: 1rem;
        text-align: justify;
        line-height: 24px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .rules-component {
        width: 85%;
        margin: auto;
    }
    .rules-data-container {
        background-image: linear-gradient(180deg, transparent,rgb(247, 247, 247));
        border-bottom: 2px solid rgba(240, 238, 238, 0.608);
        width: 91%;
        margin: 2% auto;
        padding-bottom: 12px;
    }
    .rules-container-sub-heading {
        text-align: center;
        font-size: 1.3rem;
        margin: 10px auto;
        margin-top: 33px;
        background-color: #3c55af;
        padding: 3px 0;
        width: 93%;
        color: white;
        box-shadow: 4px 5px 5px #3f3e18;
    }
    .rules-data-container p {
        margin: 2% 7%;
        /* text-align: center; */
        text-align: start;
    }
    .rules-data-container p a {
        font-size: 1.1em;
        color: blue;
        text-decoration: none;
        margin: 2% 0;
        padding: 2px 9px;
    }
}
