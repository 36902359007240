.data-container-of-campus-amenities {
    position: relative;
    width: 75%;
    margin: auto;
  }
  
  .data-container-of-campus-amenities img {
    display: block;
    width: 100%;
    margin: auto;
    height: 60vh;
  }
  
  .text-conteiner-of-campus-amenities {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #9a9a9a81;
    overflow: hidden;
    width: 100%;
    margin: auto;
    height: 0;
    transition: .5s ease-in-out;
    background-color: rgba(255, 255, 255, .15);  
    backdrop-filter: blur(5px);
   
  }
  
  .data-container-of-campus-amenities:hover .text-conteiner-of-campus-amenities {
    height: 100%;
  }
  
  .paragrauph-of-campus-amenities {
    color: rgb(0, 0, 0);
    font-size: 1.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 28px;
    font-weight: bold;
    text-shadow: 0 0 4px white;
    text-transform: none;

  }
  .paragrauph-of-campus-amenities-updated{
    text-align: justify;
    line-height: 24px;
  }

  /* Responsive */
  @media only screen and (max-width: 600px){
    .data-container-of-campus-amenities {
      width: 100%;
  }
  .data-container-of-campus-amenities {
    width: 100%;
}
.paragrauph-of-campus-amenities {
  width: 90%;
  transform: translate(-50%, -50%);
  line-height: 20px;
  font-size: .8rem;
  text-align: justify;
}
.data-container-of-campus-amenities img {

  height: 50vh;
}
  }