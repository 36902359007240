.cpio-and-faa-data-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    margin: 2% auto;
}

.cpio-and-faa-data-box{
    width: 45%;
    margin:2% auto;
transition: all 1s ease; 
padding: 1%; 
box-shadow: 0px 0px 11px 1px #afafaf;
}
.cpio-and-faa-data-box:hover{
  
    box-shadow: 0px 0px 11px 12px #afafaf;

}
.cpio-and-faa-faculty-name{
    font-size: 1.8rem;
    text-align: center;
    margin: 4% 0;
    color: red;
}

.cpio-and-faa-faculty-details {
    margin: 5% 0px;
    font-size: 1.3rem;
    line-height: 40px;
}

.cpio-and-faa-faculty-address{
    margin: 5% 0px;
    font-size: 1.3rem;
    line-height: 40px;
}