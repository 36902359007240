/* @media only screen and (max-width: 480px) and (min-width: 320px) { */
@media only screen and (max-width: 600px) {

    .sidenav-responsive-button {
        /* color: yellow; */

        /* background-color: none; */
        background: none;
        font-size: 2rem;
        border: none;
    }


    .sidenav-comp-home {
        width: 100%;
        background-color: #003366;
        padding-top: 2%;
        /* height: 100vh; */
    }

    .nav-text a {
        font-size: 12px;
        color: red;
    }

    .img-heading {
        padding: 12px;
        width: 88%;
        position: unset;
    }

    .vc-container-data {
        display: flex;
        /* flex-wrap: wrap; */
        flex-direction: column;
    }

    .photos-comp {
        width: 77%;
        margin: auto;
        padding: 2% 0;
        /* margin: 8px; */
        /* height: 45vh; */
        height: auto;
        /* border: 1px solid black; */
    }

    .vc-text-area {
        width: 88%;
        margin: auto;
    }

    .mission-and-vission-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mission-container,
    .vission-container {
        width: 79vw;
        padding: 6px;
        width: 100%;
    }

    .mission-heading {
        width: 83%;
    }

    .mission-text {
        margin-top: 3%;
        line-height: 25px;
        padding: 0 4%;
        text-align: justify;
    }

    .hr-in-home-hide-in-responsive {
        display: none;
    }

    .vission-container {
        border-left: none;
    }

    .marquee-bulletin-area {
        width: 100%;
    }

    .third-section {
        display: flex;
        flex-direction: column;
    }

    .third-sec-box {
        width: 97%;
        line-height: 21px;
    }

    .responsive-home-side-nav-for-big-screen {
        display: none;
    }

    .responsive-home-side-nav-for-small-screen {
        display: initial;
        background: linear-gradient(58deg, #b9b9b93b, #b7c2cd);
    }

    .hr-partition-for-about-and-mission-container {
        display: none;
    }

    .about-us-home-page-container {
        width: 90vw;
        padding: 13px;
    }

    .read-more-btn-for-about-us-home-page {
        display: block;
        margin: 4% auto;
        width: 30%;
        padding: 4px;
        text-align: center;
    }

    .upper-cont-comp {
        margin: auto;
        background: url('../Logo/dpsru-c.jpg')no-repeat center center fixed;
        background-color: rgba(255, 255, 255, 0.919);
        /* Set the opacity of the background color to 91% */
        background-blend-mode: lighten;
    
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    
    
    }

    .bulletain-text {
        width: 25%;
    }

    .see-all-notification-in-marquee-tab {
     width: auto;
     display: none;
    }
    
    .Recent-event-at-home-page-dpsru , .vision-for-viksit-bharat-home-page-container{
      
        width: 90vw;
        padding: 13px;
    }


}


/* Responsive For tablet  */

/* for tablet */
@media only screen and (max-width: 800px) and (min-width: 601px) {
    .vc-img {
        display: flex;
        margin: auto;
        height: 40%;
        width: 80%;


    }

    .vc-intro {
        display: flex;
        margin: 5% auto;
        justify-content: space-around;
        font-size: 1rem;
    }

    .photos-comp {
        width: 28%;
        margin: 13px 13px;
        height: 45vh;
        /* border: 1px solid black; */
    }

    .vc-text-area {
        width: 62%;
        margin-top: 3%;
        font-size: 1rem;
        line-height: 152%;
    }

    .img-heading {
        width: 57%;
        margin-top: 5%;
    }

    .vc-message {
        text-align: justify;
        padding: 0 3%;
    }

    .mission-heading {
        width: 55%;
    }

    



}