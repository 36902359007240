* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.footer {

    /* background: url('./dpsru-footer.jpg')no-repeat center center/cover; */
    background-color: #181818;
    color: #fff;


}

.footer-container {
    display: flex;
    justify-content: space-around;
}

.map-footer-sec-new a img {
    width: 13vw;
    height: 25vh;
    position: relative;
    top: 0;
    left: 0;
    filter: grayscale(100%) contrast(90%);

    /* opacity: .7; */
}

.map-footer-sec-new a img:hover {
    -webkit-filter: drop-shadow(1px 1px 0 rgba(165, 163, 18, 0.725)) drop-shadow(-1px -1px 0 rgba(164, 176, 33, 0.801));
    filter: drop-shadow(1px 1px 0 #c0be2fc7) drop-shadow(-1px -1px 0 rgba(194, 203, 33, 0.798));
    

    /* transform: skew(5deg, 2.5deg); */
}

.locate-us-heading {
    text-align: center;
    color: gray;
    margin: 1% 0;
    font-size: 1.7rem;
    font-weight: bold;
}

.footer-sec {
    padding: 2% 0;
}

.footer-short-heading {
    color: gray;
    padding: .8% 0;
}

.footer-text-sec-p {
    line-height: 24px;
    margin-bottom: 9%;
    color: #d0c9c9;
}

.footer-contact-us-dpsru {
    margin-bottom: 6%;
    text-align: left;
}

.footer-sec-middle img {
    width: 14vw;

}

.footer-sec-middle img:hover {
    /* transform: rotate(20deg); */
    width: 15vw;
    transition: all .7s ease; 
}
.footer-sec-middle img:not(:hover){
    /* transform: rotate(20deg); */
    width: 14vw;
    transition: all .7s ease; 
}

.dpsru-text-in-footer h1 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 300;
    color: var(--fontColor);
    letter-spacing: 1px;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    padding: 2% 0;
}

.dpsru-text-in-footer h1:after,
.dpsru-text-in-footer h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #003366;
    border-top: 1px solid #003366;
    height: 5px;
    background-color: #696868;
}


/* Bottom Footer */
.bottom-footer {
    background-color: #212020;
    padding: .5% 0;
}

.footer-content-dpsru {
    display: flex;
    justify-content: space-around;
}

.copyright-dpsru {
    display: flex;
    align-items: center;
    color: gray;
}

.bottom-footer-third-section {
    display: flex;
}

.bottom-footer-third-section p {
    display: flex;
    align-items: center;
    margin: 0 3px;
}

.bottom-footer-third-section p a {
    text-decoration: none;
    color: gray
}

.bottom-footer-third-section p a:hover {
    color: var(--fontColor);
}

/* icons */

.dpsru-social-media-icon {
    align-items: center;
    display: flex;
}

.dpsru-social-media-icon a {
    color: white;
    font-size: 1.3rem;
    margin: 0 .3rem;
}

.footer-facebook-icon {
    padding: 6px 8px;
    background: gray;
    border-radius: 50%;
}

.footer-facebook-icon:hover {
    color: #fff;
    background-color: #1771e6;
    box-shadow: 0px 0px 0px 2px #1771e6;
}

.footer-instagram-icon {
    padding: 6px 8px;
    font-weight: bold;
    background: gray;
    border-radius: 50%;
}

.footer-instagram-icon:hover {
    color: white;
    box-shadow: none;
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.footer-twitter-icon {
    padding: 6px 8px;
    background: gray;
    border-radius: 50%;
}

.footer-twitter-icon:hover {
    color: #fff;
    background-color: #1c93e4;
    box-shadow: 0px 0px 0px 2px #1c93e4;
}
.footer-linkedin-icon {
    padding: 6px 8px;
    background: gray;
    border-radius: 50%;
}

.footer-linkedin-icon:hover {
    color: #fff;
    background-color: #0a66c2;
    box-shadow: 0px 0px 0px 2px #0a66c2;
}

.footer-youtube-icon {
    padding: 6px 8px;
    background: gray;
    border-radius: 50%;
}

.footer-youtube-icon:hover {
    box-shadow: 0px 0px 0px 2px #f10000;
    color: #fff;
    background-color: #f10000;
}


/* avcom */
.avcom-details-in-footer {
    background-color: #181818;
    text-align: center;
    padding: 0.5% 0;
    font-size: 1rem;
    color: #282828;
}

.avcom-details-in-footer a {
    color: rgb(69 69 69);
    text-decoration: none;

}
.avcom-details-in-footer a:hover {
    color: rgb(255, 239, 19);
 

}


/* Go To Home Page */
.button-of-go-to-top {
    font-weight: bold;
    background: none;
    border: none;
    color: #d5d5d5;

}

.button-of-go-to-top:hover {
    color: rgb(223, 223, 10);
    cursor: pointer;

}

.developer-details-in-footer-container{
    position: absolute;
    bottom: 0;
    right: 2px;
    color: red;
}


/* bottom dev details */
