* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: capitalize;
}
.area-of-research-component {
  width: 85%;
  margin: 2% auto;
}
.area-of-reserch-sub-heading-of-comp {
  text-align: center;
  margin: 3% 0;
  color: white;
  background: #2c60a5;
  padding: 0.5% 0;
  font-size: 1.8rem;
  border-radius: 4px;
}

.vc-conrainer-in-area-of-research-image-box {
  display: flex;
  justify-content: center;
}
.vc-conrainer-in-area-of-research-image-box img {
  box-shadow: 1px 1px 17px 8px rgb(191, 191, 191);
  border-radius: 27px;
  width: 30%;
  height: 100%;

}
.vc-container-in-area-of-research-name-and-detail-box-paragrauph {
  width: 55%;
  margin: auto;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 26px;
  color: #1f1d1e;
}

.vc-name-in-area-of-reserch-component-inner-heading {
  font-size: 1.4rem;
  text-align: center;
  margin: 2% 0;
  color: red;
}

.links-of-vc-container-area-of-research {
  display: flex;
  width: 55%;
  margin: 1.3% auto;
  justify-content: space-between;
}
.links-of-vc-container-area-of-research span {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
}
.links-of-vc-container-area-of-research span a {
  text-decoration: none;
  color: rgb(4, 50, 178);
}
.links-of-vc-container-area-of-research span a:hover {
  text-decoration: none;
  color: rgb(255, 7, 7);
}

.area-of-reserch-sub-heading-of-container {
  text-align: center;
  margin: 3% 0;
  color: white;
  /* background: #6094da; */
  background: linear-gradient(45deg, transparent, black, transparent);
  padding: 0.5% 0;
  font-size: 1.3rem;
  border-radius: 4px;
  letter-spacing: 4px;
}
.each-data-container-of-department-of-pharmaceutics-area-of-reserch {
  width: 47%;
  margin: 3% auto;
  /* background: linear-gradient(-1deg, #d2d2d2, transparent); */
  padding: 1%;
  box-shadow: 0px 5px 15px 5px #b1b1b1;
  border-radius: 13px;
  position: relative;
  transition: all .2s ease-in-out;
  height: fit-content;
    min-height: 58vh;
}
.each-data-container-of-department-of-pharmaceutics-area-of-reserch:hover {
  box-shadow: 0px 5px 20px 8px #8a8a8a;
  width: 47.5%;
}
.each-conrainer-in-area-of-research-image-box {
  display: flex;
  justify-content: center;
  height: 51%;
  margin: auto;
}
.each-conrainer-in-area-of-research-image-box img {
  box-shadow: 3px 3px 8px 2px rgb(188 185 185);
  border-radius: 8px;
  width: 30%;
  height: 100%;
}
.each-container-in-area-of-research-name-and-detail-box {
  margin: 2% 0;
}
.each-name-in-area-of-reserch-component-inner-heading {
  font-size: 1.3rem;
  text-align: center;
  margin: 2% 0;
  color: red;
}
.each-container-in-area-of-research-name-and-detail-box-paragrauph {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 26px;
}
.content-container-of-department-of-pharmaceutics-area-of-reserch {
  display: flex;
  flex-wrap: wrap;
}
.links-of-each-container-area-of-research {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 2% 0;
  font-weight: bold;
  font-size: 1.1rem;
}
.links-of-each-container-area-of-research span {
  margin: 0;
}
.links-of-each-container-area-of-research span a {
  color: #0432b2;
  text-decoration: none;
}
.links-of-each-container-area-of-research span a:hover {
  color: red;
}


/* responsive code */
@media only screen and (max-width: 600px)
{
  .area-of-research-component {
    width: 93%;
    margin: 4% auto;
}

.vc-container-in-area-of-research-name-and-detail-box-paragrauph {
  width: 95%;
}
.links-of-vc-container-area-of-research {
  display: flex;
  width: 98%;
  margin: 4.3% auto;
  justify-content: space-between;
}


.links-of-vc-container-area-of-research span {
  margin: 0;
  font-size: .8rem;
  font-weight: bold;
}
.area-of-reserch-sub-heading-of-container {
  text-align: center;
  margin: 7% 0;
  color: white;
  background: #6094da;
  /* background: linear-gradient(45deg, transparent, black, transparent); */
  padding: 0.5% 0;
  font-size: 1.2rem;
  border-radius: 4px;
  letter-spacing: 3px;
}
.each-data-container-of-department-of-pharmaceutics-area-of-reserch {
  width: 89%;
  margin: 3% auto;
  /* background: linear-gradient(-1deg, #d2d2d2, transparent); */
  padding: 4%;
  box-shadow: 0px 5px 15px 5px #b1b1b1;
  border-radius: 13px;
  position: relative;
  transition: all .2s ease-in-out;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 50vh;
}
.each-data-container-of-department-of-pharmaceutics-area-of-reserch:hover {
  box-shadow: 0px 5px 20px 8px #8a8a8a;
  width: 90%;
}
.each-conrainer-in-area-of-research-image-box img {
  box-shadow: 3px 3px 8px 2px rgb(188 185 185);
  border-radius: 8px;
  width: 35%;
  height: 100%;
}
.links-of-each-container-area-of-research {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 8% 0;
  font-weight: bold;
  font-size: .7rem;
}
}