*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.institutions-component{
    width: 80%;
    margin: auto;
}

.institutions-sub-heading{
    background-color: rgb(0, 96, 128);
    font-size: 1.2rem;
    width: 40%;
    margin: 3% auto;
}
.navlink-of-instutions-data{
    padding: 1% 0;
color: black;
font-size: 1.2rem;
text-decoration: none;
cursor: pointer;
}
.institutions-table-conntainer{
    width: 75%;
    margin: auto;
}