.examination-syllabus-container ul{
    width: 70%;
    margin:2% auto;
    line-height: 31px;
}
.examination-syllabus-container ul li a{
   font-size: 1.3rem;
   color: black;
   text-decoration: none;
}
.examination-syllabus-container ul li a:hover{
 cursor: pointer;
   color: rgb(255, 0, 0);
  
}