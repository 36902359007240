*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.g20-panel-discussion-component{
    width: 90%;
    margin:2% auto;
}

.panel-discussion-first-img-container{
    width: 90%;
    text-align: center;
    margin: auto;
}
.panel-discussion-first-img-container img{
width: 79%;
margin: auto;

}

.panel-discussion-ol li{
color: red;
font-size: 1.8rem;
font-weight: bold;
text-align: center;
width: fit-content;
margin: auto;
}
.panel-discussion-ol li::marker{
color: rgb(151, 151, 151);

}