*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: none;
}
.placement-component{
    width: 80%;
    margin: auto;
    text-transform: none;
}
.placement-cell-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
    text-transform: none;
}
.placement-head-container{
    font-size: 1.3rem;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
}

.placement-details-text{
    text-align: justify;
    line-height: 28px;
    margin: 3% auto;
    font-size: 1.2rem;
    text-transform: none;
}

.placement-sub-heading {
    color: white;
    padding: 7px 32px;
    text-align: center;
    width: fit-content;
    margin: auto;
    font-size: 1.3rem;
    margin: 3% auto;
    background: linear-gradient(180deg, black, #0074ffb5);
    border-radius: 5px;
    text-transform: none;
}

.training-and-placement-cell-involved{
    font-size: 1.1rem;
    line-height: 30px;
    background: linear-gradient(180deg, #e9e9e9, #0000);
    padding: 1% 4%;
    border-bottom: 1px solid;
    border-bottom: 2px solid #ffffff30;
    margin-bottom: 2%;
    text-transform: none;
}
.training-and-placement-cell-involved li{
   margin: 1% 0;
   text-transform: none;
}

.placement-activities-container{
    width: 85%;
    margin: auto;
}
.placement-activities-container ul{
   /* list-style: none; */
   line-height: 26px;
}
.placement-activities-container ul li a{
  color: black;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s ease-in;
}
.placement-activities-container ul li a:hover{
  color: rgba(255, 0, 0, 0.906);
}