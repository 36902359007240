*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.ce-container-box{
    width: 45%;
    margin:3% auto;
}
.ce-container-box ul{
   line-height: 30px;
   font-size: 1.2rem;
   list-style-type: square;
}
.ce-container-box ul li{
  background:  rgb(239, 235, 246);
  margin: 6px 0;
  padding: 2px 4px;
  color: black;
}
.ce-container-box ul .alternative-li-in-cc{
  background:rgb(249, 247, 247) ;
}
.ce-container-box ul li:hover{
  background: rgb(111, 111, 111);
  color: rgb(0, 0, 0);
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .ce-container-box ul {
    line-height: 25px;
    font-size: 1rem;
    list-style-type: square;
}
}