*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.payment-component {
    width: 100vw;
    background-color: gray;
   
    min-height: 100vh;
    position: relative;
}

.payment-component::before {
  content: '';
    background: url('./paymentbg.jpg');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
    filter: blur(4px);
}

.payment-container{
    width: 80%;
    margin: auto;
    position: relative;
}

.payment-text {
    text-align: center;
    margin: 6% auto;
    color: #ffffff;
}

.payment-text a {
    color: var(--fontColor);
    padding: 4px 14px;
    font-weight: bold;
    letter-spacing: 4px;
    /* textrgb(1, 1, 1)adow: 2px 2px 2px yellow; */
}
.payment-text a:hover {
    color: red;

}

.dpsru-text-in-payment{
    text-align: center;
    margin: 3% auto;
    color: var(--fontColor);
    font-weight: bold;
    letter-spacing: 4px;
    text-shadow: 0 0 4px black;
    font-size: 2rem;
    position: relative;
}

.how-to-pay-dpsru{
    color: var(--fontColor);
    margin: 2% 0;
    font-weight: bold;
    font-size: 1.3rem;
}

.card-body-payment-how-to-pay{
  
    font-size: 1.2rem;
    line-height: 29px;
}
.card-body-payment-how-to-pay p{
    color: rgb(200, 160, 30);
    font-size: 1.4rem;
margin: 2% 0;
text-shadow: 0 0 15px black;
}
.card-body-payment-how-to-pay ul li{
   color: white;
    text-shadow: 0 0 5px black;
    margin: 1% 0;
}


/* ************************************* */
