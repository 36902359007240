.world-heart-day-second-heading-head-name{
    margin: 1% auto;
    text-align: center;
    color: red;
}

.whd-first-text-container{
    font-size: 1.3rem;
    text-align: justify;
    margin: 2% auto;
}

.whd-single-image-container{
    text-align: center;
}

.worldHeartDay-first-image-container{
    display: flex;
    flex-wrap: wrap;
}

.worldHeartDay-first-image-container img{
    width: 45%;
    margin: 1% auto;
    transition: all .4s ease;
}
.worldHeartDay-first-image-container img:hover{
    scale: 1.1;
    box-shadow: 0 0 9px 4px gray;
}

.worldHeartDay-second-image-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.image-box-whd-second{
    width: 30%;
}
.image-box-whd-second p{
   text-align: center;
   font-weight: bold;
   margin-top: 2%;
}