.governingBoard-img-container{
display: flex;
flex-wrap: wrap;
}
.governingBoard-box{
    width: 26vw;
    margin: auto;
    /* height: 33%; */
    padding-top: 4px;
    box-sizing: border-box;
}
.governingBoard-box img{
  width: 60%;
  /* height: 250px; */
  height: 280px;
  border: 1px solid black;
}
.governingBoard-box:hover{
    /* box-shadow: 2px 4px 4px 4px rgb(191, 187, 187); */
    background-color: rgb(253, 252, 252);
    /* border: .4px solid rgb(254,252,252); */

}

.governingBoard-box-img{
    display: block;
    margin: auto;
}
.governingBoard-box-para{
    font-style: italic;
    text-align: center;
    margin: 4% 0;
    font-size: 1.1rem;
    line-height: 22px;
    color: #131313;
}

.administration-gc-heading-sub-2 {
    text-align: center;
    font-size: 1.5rem;
    margin: 2% auto;
    margin-top: 33px;
    color: white;
    background-color: rgb(46 65 146);
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 7px 0;
    display: block;
    width: 95%;
}

.governing-board-harvinder-popli{
    width: 40%;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {

    .governingBoard-box{
        width: auto;
    }
}