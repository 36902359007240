*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.recruitments-component {
    width: 80%;
    margin: 0 auto;
    line-height: 212%;
}

.sub-heading {
    background: #3e3ea1;
    color: white;
    font-size: 1.4rem;
    width: 49%;
    margin: 3% auto;
    text-align: center;
    padding: 5px;
   
}

.recruitments-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.miscellinus-section p{
    margin: 16px 0;
}
.miscellinus-section p a{
    padding: 5px 10px;
    text-decoration: none;
    font-size: 1.3rem;
    color: #222;
    transition: all .2s ease;
}
.miscellinus-section p a:hover{
     
        color:#ff0000;
     
     }

     @media only screen and (max-width: 480px) and (min-width: 320px) {

        .sub-heading {
            background: #3e3ea1;
            color: white;
            font-size: 1.2rem;
            width: 83%;
            margin: 3% auto;
            text-align: center;
            padding: 5px;
        }
    }