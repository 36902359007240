.image-and-text-container-of-lab-details-computer-center {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 1% auto;
}
.image-and-text-container-of-lab-details-computer-center-second {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 1% auto;
    flex-direction: row-reverse;
}
.image-and-text-container-of-lab-details-computer-center img {
    width: 40%;
}
.paragrauph-of-lab-details-computer-center{
    width: 37%;
    font-size: 1.2rem;
    line-height: 33px;
    /* margin: auto; */
    text-align: justify;
    font-family: ui-serif;
}
.server-room-computer-center-container {
    display: flex;
    width: 82%;
    margin: auto;
    justify-content: space-around;
}
.server-room-computer-center-container img{
   width: 35%;
}
.server-room-computer-center-container p{
   width: 60%;
   font-size: 1.2rem;
    line-height: 33px;
    text-align: justify;
    font-family: ui-serif;
   
}
.labs-details-in-computer-center-section-container{
    width: 82%;
    margin:2% auto;
}
.lab-details-of-each-lab-box-first {
    background: #fdfcfc;
    padding: 2%;
}

.lab-details-computer-center-heading {
    font-weight: bold;
    background: #3b8167;
    text-align: center;
    margin: 1% auto;
    padding: 5px 0;
    color: white;
}
.for-container-of-computer-center{
    width: 60%;
    margin: auto;
}
.for-container-of-computer-center li{
  margin: 1% auto;
}
.for-container-of-computer-center li a{
  font-size: 1.3rem;
  color: rgb(31, 31, 31);
  text-decoration: none;
}
.for-container-of-computer-center li a:hover{
  font-size: 1.3rem;
  color: rgb(255, 0, 0);
  text-decoration: none;
}

    .it-incharge-wrapper-container-dpsru{
        display: flex;
    }

@media only screen and (max-width: 600px)
{
    .server-room-computer-center-container {
        display: flex;
        width: 82%;
        margin: auto;
        justify-content: space-around;
        flex-direction: column;
    }
    .server-room-computer-center-container img {
        width: 100%;
    }
    .server-room-computer-center-container p {
        width: 100%;
        font-size: 1.2rem;
        line-height: 28px;
        text-align: justify;
        font-family: ui-serif;
    }
    .image-and-text-container-of-lab-details-computer-center {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
        margin: 1% auto;
        flex-direction: column;
    }
    .image-and-text-container-of-lab-details-computer-center img {
        width: 100%;
    }
    .paragrauph-of-lab-details-computer-center {
        width: 100%;
        font-size: 1.2rem;
        line-height: 33px;
        /* margin: auto; */
        text-align: justify;
        font-family: ui-serif;
    }
    .it-incharge-wrapper-container-dpsru{
        display: initial;
    }
}