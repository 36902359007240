*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.whatsnew-component{
    width: 85%;
    margin: auto;
}
.whatsnew-heading{
    text-align: center;
}

/* .whatsnew-data-container ul li{
margin: 2% 0;
background-color: rgb(247, 243, 243);
padding: 0.5% ;
border-radius: 9px;
}


.whatsnew-data-container ul li a:hover{
    color: red;
} */
.whats-new-data{
background:none;
}