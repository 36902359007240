.img-container-of-say-no-to-drug-page{
    width: 99%;
    margin: auto;
}
.img-container-of-say-no-to-drug-page img{
    width: 100%;
    margin: auto;
}

.program-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    line-height: 1.6;
  }
  
  .program-container h1 {
    text-align: center;
    color: #2c3e50;
  }
  
  .program-container h2 {
    text-align: center;
    color: #34495e;
  }
  
  .program-container h3 {
    text-align: center;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  .program-container p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .program-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .program-table th,
  .program-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .program-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .program-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .program-table tr:hover {
    background-color: #f1f1f1;
  }

  .say-no-to-drug-image-and-video-container{
    display: flex ;
    width: 100%;
    flex-wrap: wrap;
  }
  .say-no-to-drug-image-and-video-each-box{
    width: 40%;
    margin: auto;
  }
  .say-no-to-drug-image-and-video-each-box iframe{
   width: 100%;
   height: 400px;
  }
  .say-no-to-drug-image-and-video-each-box img{
   width: 100%;
   height: 400px;
  }


  @media only screen and (max-width: 600px) {
    .say-no-to-drug-image-and-video-container{
        display: flex ;
        width: 100%;
       flex-direction: column;
      }
  .say-no-to-drug-image-and-video-each-box{
    width: 95%;
    margin: auto;
  }
  }