* {
  text-transform: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-transform: none;
}
.App {
  text-align: center;
  text-transform: none;
}

:root {
  --primaryBlue: #003366;
  /* --primaryBlue: rgb(52, 167, 182); */
  --fontColor: #ffca07;
}

/* for selected text css */
::selection {
  color: rgb(255, 230, 0);
  background: rgb(87, 87, 87);
  /* background: none; */
}

/* Developer details  */
.developer-details-container {
  background-color: #282c34;
  width: 100%;
}

.a-tag-text-transorm-none{
  text-transform: none;
}

.devDetails-link {
  background-color: #282c34;
  color: white;
  font-size: 1.3rem;
  font-family: cursive;
  text-align: center;
  display: block;
  margin: auto;
  text-decoration: none;
}
.devDetails-link:hover {
  background-color: #ffa408;
  color: rgb(0, 0, 0);
}
.new-tag-flag-dpsru {
  width: 50px;
  position: relative;
  bottom: -10px;
  z-index: 999;
  left: 10px;
  transition: all 0.3s ease;
}

/* heading for every page should be same */

.dpsru-primary-heading-for-every-page {
  text-align: center;
  font-size: 2rem;
  margin: 10px auto;
  margin-top: 33px;
  background-color: #2f2f2f;
  color: var(--fontColor);
  box-shadow: 3px 6px 8px 0px #343434;
  padding: 5px;
  text-transform: none;
}

/* secondary heading of every page */
.dpsru-secondary-heading-for-every-page {
  text-align: center;
  color: white;
  margin: 3% auto;
  padding: 6px 0;
  background: radial-gradient(
    circle at center,
    #000000 0%,
    #858585 50%,
    #ffffff 100%
  );
  font-size: 1.4rem;
  text-transform: none;
}

/* every component width size should be same */
.dpsru-every-component-css {
  width: 85%;
  margin: 1% auto;
}

/* each faculty css */
.each-faculty-component-page {
  width: 80%;
  margin: auto;
}
.faculty-image-container-for-each-page{
  margin:3% auto;
  display: block;
  text-align: center;
}
.faculty-image-container-for-each-page img{
  width: 22%;
  box-shadow: 0 0 40px 1px gray;
}
.faculty-name-for-each-container{
  font-size: 2rem;
  font-weight: bold;
  margin: 1% auto;
  text-align: center;
}
.facult-designation-for-each-container{
  background-color: rgb(235 234 234);
  padding: 4px ;
  text-align: center;
}

.faculty-contact-and-research-impact-details-each-container{
  display: flex;
  justify-content: space-evenly;
  margin: .6% auto;
}
.contact-heading-of-faculty-details{
  text-align: center;
  color: rgb(179, 179, 179);
  margin: .8% auto;
  text-transform: none;
}
.faculty-contact-details-email-for-each-page{
  margin: .9% auto;
  font-size: 1.1rem;
}
.faculty-contact-details-email-for-each-page a{
 color: black;
  text-transform: lowercase;
 text-decoration: none;
}
.faculty-contact-details-email-for-each-page a:hover{
 color: rgb(0, 55, 255);
}
.academic-qualification-heading-for-every-page {
  text-align: center;
  color: white;
  margin: 1.5% auto;
  padding: 6px 0;
  background: radial-gradient(
    circle at center,
    #000000 0%,
    #858585 50%,
    #ffffff 100%
  );
  font-size: 1.4rem;
}

.academic-qualification-for-all-container-paragrauph{
  font-size: 1.2rem;
  text-align: justify;
  margin-bottom: 1%;
}

.inside-container-text-of-faculty{
  font-size: 1.1rem;
  line-height: 23px;
}

/* naac file and dipsar faculty files for ol */
.inner-container ol li {
  margin: 15px 0;
  line-height: 150%;
  font-size: 1.2rem;
  padding-left: 16px;
}
.inner-container p {
  margin: 15px 0;
  line-height: 150%;
  font-size: 1.2rem;
  padding-left: 16px;

}
.inner-container ol li::marker {
 color: #000;
}

.inner-container ol li a {
  font-size: 18px;
  text-decoration: none;
  padding: 3px 6px;
  color: #004fb1;
  font-weight: bold;
  border-radius: 5px;
  line-height: 28px;
  text-transform: none;
}

.inner-container ol li a:hover {
  color: #ff0b0b;
 
  /* background: linear-gradient(45deg, rgba(255, 0, 0, 0.51), rgba(0, 0, 255, 0.625)); */
}

.intro-of-alied{
  font-size: 1.2rem;
  width: 80%;
  margin: auto;
}
.intro-of-alied ul{
 
  line-height: 28px;
}
.intro-of-alied ul li{
margin: 1.5% 0;
}
.datesheet-ul-container li a{
  text-transform: none;
}