* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.naac-container {
  width: 80%;
  margin: 3% auto;
 ;
}
.naac-data-container{
  animation: paperRoll 1.2s ease-in-out;
  transform-origin: top;
  overflow: hidden;
}
.dpsru-naac-grade-declearation{
  text-align: center;
  margin:1% auto;
  color: rgb(206, 0, 0);
  font-size: 1.23rem;
}

.peer-team-bnt-container{
  margin: 1% 0;
  padding: 2% 0;
}
/* .peer-team-visit-btn{
 color: #e3b409;
 text-decoration: none;
 font-size: 1.3rem;
 background-color: rgb(19, 19, 19);
 padding: 8px 10px;
 border-radius: 9px;
 font-weight: bold;
 transition: all .3s ease;
}

 */

/* button typing text */

.wrapper-of-peer-team-visit-btn {
  
  /*This part is important for centering*/
  display: grid;
  place-items: start;
  /* background: rgb(42, 42, 42); */
  border: 2px solid;
  /* width:200px; */
  width: fit-content;
  border-radius: 8px;
  transition: all .3s ease;
}
.wrapper-of-peer-team-visit-btn {
  
  /*This part is important for centering*/
  display: grid;
  place-items: start;
  /* background: rgb(42, 42, 42); */
  border: 2px solid;
  /* width:200px; */
  /* width: fit-content; */
  border-radius: 8px;
  transition: all .3s ease;
}
.wrapper-of-peer-team-visit-btn:hover {
  background-color: rgb(215, 215, 215);
  box-shadow: 0 0 6px 1px rgb(220, 220, 220);
  border:2px solid red;
}

.peer-team-visit-btn {
    width: 50ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    /* border-right: 3px solid; */
    font-family: monospace;
    font-size: 1.5em;
    color: #181818;
    padding: 3px 20px;
    border-radius: 7px;
    text-decoration: none;
}
@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.peer-team-visit-btn:hover{
  color: rgb(255, 14, 14);
 
}
/* button typing text */




.naac-sub-heading {
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin: 1.4% 0;
  color: red;
  text-align: center;
}

.naac-button {
  /* background-color: #0657a9; */
  background-color: #303030 !important;
  box-shadow: 4px -3px 6px #c8c8c8;
  color: #e3b409;
  font-size: 1.3rem;
  padding: 11px 10px;
  width: 100%;
  margin: 10px 0;
  text-align: left;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none;
  border: none;
transition: all .2s ease-in-out;
opacity: 999;
text-transform: none;
}
.naac-button:hover {
 cursor: pointer;
}

/* csssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss */
.naac-button-false span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.naac-button-false span:after {
  content: '	\25bc';
  position: absolute;
  opacity: 0;
  top: 1px;
  /* right: -30px; */
  transition: 0.5s;
}

.naac-button-false:hover span {
  padding-right: 50px;
}

.naac-button-false:hover span:after {
  opacity: 1;
  right: 0;
}

/* true */
.naac-button-true span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.naac-button-true span:after {
  content: '\25b2';
  position: absolute;
  opacity: 0;
  top: 1px;
  /* right: -30px; */
  transition: 0.5s;
}

.naac-button-true:hover span {
  padding-right: 50px;
}

.naac-button-true:hover span:after {
  opacity: 1;
  right: 0;
}
/* csssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss */

.naac-button:hover {
  box-shadow: 3px 3px 6px 1px rgb(105, 105, 105);
  color:  #ffd53b;
  position: relative;
  bottom: 2px;
  box-shadow: 4px 3px 12px #333232;

}

.all-tabs-open-naac-files {
  display: inline;
  float: right;
  color: black;
  background: whitesmoke;
  padding: 0px 13px;
  border-radius: 6px;
  font-weight: bold;
  /* border: 1px solid black; */
  transition: all .2s ease;
  font-size: 1.3rem;
  position: relative;
  right: 1%;
}
.all-tabs-open-naac-files svg {
    width: fit-content;
    height: fit-content;
    font-size: 2.2rem;
}
.all-tabs-open-naac-files:hover {
  color: #e3b409;
 background-color:#303030;
 box-shadow: 0 0 5px 2px rgba(104, 104, 104, 0.841);
}

.inner-container {
  padding: 3%;
  border-radius: 30px;
  background: #e9e9e9;
  animation: paperRoll .7s ease-in-out;
  transform-origin: top;
}

@keyframes paperRoll {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1)
}
  100% {
    transform: scaleY(1);
  }
}



.inner-container-heading {
  font-size: 1.2rem;
  margin: 1.5% 0;
  color: #000;
  font-weight: bold;
  text-transform: none;
}

.inner-container ul li {
  margin: 15px 0;
  line-height: 150%;
  font-size: 1.2rem;
  padding-left: 16px;
}
/* .inner-container ul li::marker {
 content: '⚫'; 
 color: #000;
} */

.inner-container ul li a {
  font-size: 18px;
  text-decoration: none;
  padding: 3px 6px;
  color: #004fb1;
  font-weight: bold;
  border-radius: 5px;
  line-height: 28px;
  text-transform: none;
}

.inner-container ul li a:hover {
  color: #ff0b0b;
 
  /* background: linear-gradient(45deg, rgba(255, 0, 0, 0.51), rgba(0, 0, 255, 0.625)); */
}

/* section 2  */

.extended-profile-deviations-section-of-naac {
  margin: 2% 0;
}

.extended-profile-deviations-section-of-naac ul li {
  margin: 1.4% 0;
}

.extended-profile-deviations-section-of-naac ul li a {
  font-size: 1rem;
  font-weight: bold;
  padding: 4px 9px;
  color: black;
  border-radius: 5px;
  transition: all .2s ease;
}

.extended-profile-deviations-section-of-naac ul li a:hover {
  color: #ff0b0b;
 
}


.container-wrapper-button-of-naac-peer-team{
  display: flex;
  justify-content: space-between;
  margin: 0 2%;
  margin-top: 3%;

}
/* Responsive */
@media only screen and (max-width: 600px) {
  .extended-profile-deviations-section-of-naac ul li {
    margin: 7.4% 0;
  }

  .wrapper-of-peer-team-visit-btn {
    display: grid;
    place-items: start;
    background: rgb(42, 42, 42);
    width: 82%;
    border-radius: 8px;
}
}
