.graviance-form-component{
  background: url('./grievenceBg.jpg')no-repeat center center/cover;
  width: 100%;
}

.form-container-of-grevance-form {
  
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  width: 60%;
  margin: auto;
  padding: 2%;
  animation: paperRollGravieanceForm 1s ease;
  transform-origin: top;
  overflow: hidden;

}
@keyframes paperRollGravieanceForm {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1)
}
  100% {
    transform: scaleY(1);
  }
}

.box-of-form-container-of-grevance-form{
  position: relative;
  
    
}
.box-of-form-container-of-grevance-form label{
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #fff;
  pointer-events: none;
  transition: .5s;

}
.box-of-form-container-of-grevance-form input{
  width: 100%;
  padding: 10px 0;
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.box-of-form-container-of-grevance-form input:focus ~ label,
.box-of-form-container-of-grevance-form input:valid ~ label {
  top: -20px;
  left: 0;
  color: #f3c103;
  font-size: 1.11rem;
}
.box-of-form-container-of-grevance-form select, .box-of-form-container-of-grevance-form select option{
  width:60%;
  margin: auto;
  padding: 1%;
  outline: none;
  font-size: 1.4rem;
  background: none;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
 .box-of-form-container-of-grevance-form select option{
  background: rgba(5, 5, 5, 0.79);
  color: rgb(255, 255, 255);
  font-size: 1.1rem;
}

.submit-button-of-grievance-form{
  position: relative;
  /* display: block; */
  padding: 10px 20px;
  color: #f3c103;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px;
  background: none;
  border: none;
  outline: none;
  left: 70%;
}
.submit-button-of-grievance-form:hover{
  background: #f3c103;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #f3c103,
              0 0 25px #f3c103,
              0 0 50px #f3c103,
              0 0 100px #f3c103;

}
.submit-button-of-grievance-form span{
  position: absolute;
  display: block;
}
.submit-button-of-grievance-form span:nth-child(1){
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #f3c103);
  animation: btn-anim1 1s linear infinite;
}



@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.submit-button-of-grievance-form span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #f3c103);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.submit-button-of-grievance-form span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f3c103);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.submit-button-of-grievance-form span:nth-child(4) {
  bottom: -100%;
  left:-15px;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f3c103);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

/* RESPOSNSIVE */
@media only screen and (max-width: 600px){
  .form-container-of-grevance-form {
    width: 100%;
}

.box-of-form-container-of-grevance-form input, .box-of-form-container-of-grevance-form select {
  width: 90%;
}
}