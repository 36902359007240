@import url('https://fonts.googleapis.com/css2?family=Aladin&family=UnifrakturMaguntia&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ewert&display=swap');
* {
    margin: 0;
    padding: 0;
    text-transform: auto;

}

.developer-details-component {
    width: 100%;
    margin: auto;
    background-color: #0e123f;
    /* background-color: rgb(18, 22, 90); */
    /* color: white; */
}

.developer-component-heading {
    background-color: #ffa500;
    /* box-shadow: 1px 3px 4px 4px rgb(223, 223, 223); */
    padding: 4px 0;
    /* background-image: linear-gradient(45deg, #ffa500, green, blue,yellow,skyblue,pink,red); */
    background: linear-gradient(148deg, transparent, #f0f403, transparent);
    color: white;
    text-shadow: 2px 2px 2px black;
    font-weight: bold;
    width: 100%;
    font-family: cursive;
    margin: 2% 0;
    font-size: 1.5rem;
    text-align: center;
}

.dev-name {
    font-size: 2rem;
    font-weight: bold;
    color: rgb(97, 97, 97);
    letter-spacing: 5px;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 8px;
    font-family: 'Aladin', cursive;
}
.dev-name a{
    color: rgb(97, 97, 97);
    letter-spacing: 5px;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 8px;
    font-family: 'Aladin', cursive;
    position: relative;
    transition: all .8s ease;
}
/* .dev-name a:hover{
  color: yellow;
} */

.dev-email-details {
    font-size: 2rem;
    font-weight: bold;
    color: rgb(97, 97, 97);
    letter-spacing: 5px;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.5s;
    padding: 4px 8px;
    font-family: 'Aladin', cursive;
    text-transform: lowercase;
}
.dev-email-details a{
    color: rgb(97, 97, 97);
    letter-spacing: 5px;
    cursor: pointer;
    text-transform: lowercase;
    text-decoration: none;
    transition: 0.5s;
    padding: 4px 8px;
    font-family: 'Aladin', cursive;
    text-transform: lowercase;
    position: relative;
    transition: all .8s ease;
}

.dev-name a:hover,
.dev-email-details a:hover{
     /* background: linear-gradient(360deg, black, transparent);  */
     color: #fff; 
    text-shadow: 0 0 5px #03e9f4,
        0 0 20px #03e9f4,
        0 0 40px #03e9f4,
        0 0 80px #03e9f4; 
    font-size: 2.2rem;
        /* color: yellow; */

 } 

.from-avcom-dev{
    color: yellow;
    cursor: context-menu;
}
.from-avcom-dev:hover{
    color: #fff;
    text-shadow: 0 0 5px #03e9f4,
        0 0 25px #03e9f4,
        0 0 50px #03e9f4,
        0 0 100px #03e9f4;
}


.developer-details-component p {
    /* font-size: 1.8rem; */
    margin: 2% 0;
    text-align: center;

}

.developer-details-component {
    color: white;
    background: black;
}

.developer-details-component p span {
    font-weight: bold;
}

.developer-social-media-link {
    width: 50%;
    margin: auto;
}

.developer-social-media-link ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 3% 0;
}

.developer-social-media-link ul li {
    margin: 0 12px;

}

.developer-social-media-link ul li a .developer-social-media {
    color: gray;
    font-size: 2em;
    font-weight: bold;
    box-shadow: 1px 1px 4px 2px gray;
    border-radius: 50%;
    padding: 4px;
    background-color: rgb(238, 237, 237);

}

.developer-social-media-link ul li a .dev-insta:hover {
    color: red;
    box-shadow: 1px 1px 7px 2px red;
}

.developer-social-media-link ul li a .dev-whatsapp:hover {
    color: #25d366;
    box-shadow: 1px 1px 7px 2px #25d366;
}

.developer-social-media-link ul li a .dev-linkedin:hover {
    color: #0073b1;
    box-shadow: 1px 1px 7px 2px #0073b1;
}

.developer-social-media-link ul li a .dev-phone:hover {
    color: #54cb01;
    box-shadow: 1px 1px 7px 2px #54cb01;
}

.developer-social-media-link ul li a .dev-mail:hover {
    color: #eb4134;
    box-shadow: 1px 1px 7px 2px #eb4134;
}

.developer-social-media-link ul li a .dev-link:hover {
    color: #333;
    box-shadow: 1px 1px 7px 2px #333;
}



/* name style  */
/* name style  */

.developerName-of-dpsru {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #14213d; */
  }
  
  .developer-name-text-box-of-dpsru span {
    font-size: 3rem;
    padding: 0 10px;
    text-transform: uppercase;
    color: #fff;
    animation: zoomup 2s linear infinite;
    animation-delay: calc(200ms * var(--i));
    font-family: 'Ewert', cursive;
  }
  
  @keyframes zoomup {
    0%,
    100% {
      color: #c23d00;
      filter: blur(1px);
      text-shadow: 0 0 10px #b8c200, 0 0 20px #bfc200, 0 0 30px #c2b800,
        0 0 40px #bcc200, 0 0 60px #c2bc00, 0 0 80px #c2bc00, 0 0 100px #afc200,
        0 0 120px #b5c200;
    }
  
    5%,
    95% {
      filter: blur(0);
      color: #fff;
      text-shadow: none;
    }
  }

/* name style  */
/* name style  */


/* Responsive media query */
@media only screen and (max-width: 600px) {

    .dev-email-details,
    .dev-name {
        font-size: 1.3rem;
    }
}