.upper-navigation-quick-link-container {
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: wrap;
    background: linear-gradient(0deg, rgb(11 109 207),#0b6dcf);
    padding: 1px 28px;
}
.upper-navigation-quick-link-container li{
   list-style: none;
   margin: 15px auto;
}
.upper-navigation-quick-link-container li a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: .89rem;
    /* padding: 18px 2px; */
    transition: all .2s ease;
}
.upper-navigation-quick-link-container li a:hover {
   cursor: pointer;
   color: #f0c000;


}