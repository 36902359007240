.internation-accolades-image-container{
    display: flex;
    flex-wrap: wrap;
}

.internation-accolades-image-box{
    width: 30%;
    margin: auto;
    text-align: center;
}
.internation-accolades-image-box img{
width: 100%;
height: 80%;
box-shadow: 0px 0px 14px -2px;
margin: 2% auto;
}