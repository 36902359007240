/* @media only screen and (max-width: 480px) and (min-width: 320px) { */
@media only screen and (max-width: 600px) {

    .footer-container {
        /* width: 1170px; */
        padding: 2%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .footer-contact-us-dpsru {
        margin-bottom: 6%;
        text-align: center;
    }
    .footer-sec {
        width: 93%;
        text-align: center;
        padding: 5px;
        margin: 1% auto;
    }
    .footer-text-sec-p {
        line-height: 26px;
        margin-bottom: 3%;
        color: #d0c9c9;
    }
   
.footer-sec-middle img:not(:hover)  {
        width: 56vw;
    }
    .map-footer-sec-new a img {
        width: 75vw;
        height: 29vh;
    }

    .dpsru-text-in-footer h1 {
        text-align: center;
        font-size:.6rem;
        font-weight: 100;
        color: #f9ce22;
    }
    .footer-content-dpsru {
        display: flex;
        /* justify-content: space-around; */
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .avcom-details-in-footer a {
        color: rgb(69 69 69);
        text-decoration: none;
        font-size: .7rem;
    }
    .copyright-dpsru {
        display: flex;
        align-items: center;
        color: gray;
        padding: 1% 0;
    }
    .dpsru-social-media-icon {
        padding: 1%;
        align-items: center;
        display: flex;
    }
    .bottom-footer-third-section {
        display: flex;
        padding: 1%;
        justify-content: center;
    }
    .dpsru-text-in-footer h1:after, .dpsru-text-in-footer h1:before {
        content: " ";
        display: block;
        border-bottom: 1px solid #003366;
        border-top: 1px solid #003366;
        height: 1px;
        background-color: #696868;
    }
}


/* for tablet */
@media only screen and (max-width: 800px) and (min-width: 601px) {
.dpsru-text-in-footer h1 {

    font-size: 1rem;
    
}
}