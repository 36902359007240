.dpsru-home-bottom-tabs-container{
    display: flex;
    margin: auto;
    justify-content: center;
}
.dpsru-bottom-tabs-each-box{
    width: 32%;
}

.read-more-button-container-of-home-page-tab{
    width: 100%;
    text-align: right;
}

@media only screen and (max-width: 600px)
{
    .dpsru-home-bottom-tabs-container{
        display: flex;
        margin: auto;
        justify-content: center;
        flex-direction: column;
    }

    .dpsru-bottom-tabs-each-box{
        width: 90%;
    }
}