* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
    
}

.staff-each-image-container{
    display: flex;
    flex-wrap: wrap;
}
.staff-each-image-box{
   width: 30%;
 text-align: center;
   margin: 1% auto;
}

.staff-each-image-box-secondary-box-small{
    width: 23%;
    text-align: center;
      margin: 1% auto;
}

.staff-each-image-img-main{
    width: 80%;
    height: 80%;
}
.staff-each-image-img{
    width: 80%;
    height: 60%;
}

.staff-each-image-paragrauph{
    font-weight: bold;
    margin: 1% auto;
    background-color: rgb(227, 227, 227);
    padding: 4px 0;
}