*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.wcsc-component{
    width: 80%;
    margin: auto;
}

.wcsc-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
}
.img-box img {
    width: 79vw;
    height: 65vh;
    /* border: 1px solid green; */
}

.wcsc-sub-heading{
    margin: 3% auto;
    text-align: center;
    background-color: #2323bcf6;
    color: rgb(255, 255, 255);
    padding: 6px 2px;
    width: 67%;
    font-size: 1.3rem;
}


.about-ul{
    line-height: 33px;
}
.about-ul li{
   color: red;
   font-size: 1rem;
   font-weight: bold;
}
.wcsc-paragraph{
    line-height: 30px;
    text-align: justify;
    font-size: 1.2rem;
    padding: 2% 0;
}
.wcsc-inner-heading{
    margin: 3% 0;
    background-color: #303085f6;
    color: rgb(255, 255, 255);
    padding: 3px 2px;
    width: 13%;
    font-size: 1.3rem;
    text-align: center;

}
.wcsc-inner-heading-long{
    margin: 3% 0;
    background-color: #303085f6;
    color: rgb(255, 255, 255);
    padding: 3px 2px;
    width: 30%;
    font-size: 1.3rem;
    text-align: center;

}

.head-heading{
    color: #2323bcf6;
    text-align: center;
    font-size: 1.7rem;
    margin: 2% 0;
    text-shadow: 1px 1px 3px #9b3131;
    text-decoration: underline;
}

.wcsc-gallary-carousel{
/* border: 2px solid red; */
width: 80vw;
}
.wcsc-gallary-carousel img{
width: 80vw;
height: 70vh;
}

.our-activities-img-container-wcsc{
    width: 98%;
    display: flex;
    justify-content: space-between;
}
.our-activities-img-container-wcsc img{
    width: 98%;
    height: 86%;   
}

.wcsc-covid-container{
    padding: 3% 0;
}
.wcsc-covid-container a{
   font-size: 1.4rem;
   padding:1%;
   text-shadow: .5px .5px .5px red;
}
.wcsc-covid-container a:hover{
  
   color: rgb(255, 0, 0);
}
.wcsc-text-heading-for-responsive{
    text-align: center;
    color:#4054b2 ;
    margin: 2%;
    font-size: 1.3rem;
}
.wcsc-beauty-wellness-responsive-h4{
    /* style={{ fontSize: '1.5rem', color: 'red', margin: '1.4% 0' }} */
    font-size: 1.4rem;
    color: red;
    margin: 1.4% 0;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .wcsc-component {
        width: 76%;
        margin: auto;
    }
    .wcsc-heading {
        text-align: center;
        font-size: 1.3rem;
        margin: 10px;
        margin-top: 33px;
        background-color: #373782;
        color: white;
        box-shadow: 4px 5px 5px #3f3e18;
    }
    .wcsc-text-heading-for-responsive {
        text-align: center;
        color: #4054b2;
        margin: 6% 0px;
        font-size: 1rem;
    }
    .img-box img {
        width: 70vw;
        height: 38vh;
        margin: auto;
        display: block;
    }
    .about-ul li {
        color: red;
        font-size: .8rem;
        font-weight: bold;
        line-height: 26px;
    }
    .wcsc-paragraph {
        line-height: 26px;
        text-align: justify;
        font-size: .95rem;
        padding: 3% 3px;
    }
    .wcsc-sub-heading {
        margin: 5% auto;
        text-align: center;
        background-color: #2323bcf6;
        color: rgb(255, 255, 255);
        padding: 6px 2px;
        width: 93%;
        font-size: 1.3rem;
    }
    .wcsc-inner-heading {
        margin: 3% 0;
        background-color: #303085f6;
        color: rgb(255, 255, 255);
        padding: 3px 2px;
        width: 50%;
        font-size: 1.1rem;
        text-align: center;
    }
    .wcsc-inner-heading-long {
        margin: 3% 0;
        background-color: #303085f6;
        color: rgb(255, 255, 255);
        padding: 3px 2px;
        width: 93%;
        font-size: 1.1rem;
        text-align: center;
    }
    .wcsc-beauty-wellness-responsive-h4{
        font-size: 1rem;
        color: red;
        margin: 1.4% 0;
    }
    .head-heading {
        color: #2323bcf6;
        text-align: center;
        font-size: 1.5rem;
        margin: 4% 0;
        text-shadow: 1px 1px 3px #9b3131;
        text-decoration: underline;
    }
    .wcsc-gallary-carousel img {
        width: 76vw;
        height: 34vh;
    }
    .inner-container ul li a {
        font-size: 14px;
        text-decoration: none;
        padding: 6px 3px;
    }
    .inner-container ul li {
       margin: 8px 3.5%;
    }
    .our-activity-img-container-wcsc-each{
        height: 30%;
    }
}