* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.forms-container ul {
    width: 75%;
    margin:2% auto;
}

.forms-container ul li {
    margin: 2% 0;
}

.forms-container ul li a {
    padding: 6px 18px;
    color: #333;
    text-decoration: none;
    font-size: 1.3rem;
    transition: all .5s ease;
    text-align: justify;
}

.forms-container ul li a:hover {
    color: #ff0909;
   
}



/* Responsive media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {

    .form-heading {
        padding: 2px;
        color: white;
        background-color: #292970;
        font-size: 2rem;
        text-align: center;
        width: 73%;
        margin: 3% auto;
    }
    .forms-container ul {
        width: 81%;
        margin: auto;
        list-style: none;
    }
    .forms-container ul li {
        margin: 7% 0;
        border: 1px solid black;
    }
    .forms-container ul li a {
        padding: 9px 18px;
        color: #333;
        text-decoration: none;
        font-size: 1.1rem;
    }
}