.logo-cmys-container{
    width: 20%;
    margin: 2% auto;
}
.logo-cmys-container img{
    width: 100%;
    margin: auto;
}

.cmys-logo-paragrauph{
    width: 40%;
    margin: auto;
    color: rgb(181, 1, 1);
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
}

.cmys-paragauph-first{
    width: 90%;
    margin:2% auto;
    text-align: justify;
    font-size: 1.2rem;
    line-height: 29px;

}
.cmys-mission-vision-container{
    display: flex;
    justify-content: space-between;
}

.cmys-mission-vision-each-box{
    width: 48%;
    text-align: center;
}

.cmys-obtectives-ol{
    width: 90%;
    margin: auto;
}
.cmys-obtectives-ol li{
font-size: 1.2rem;
color: black;
margin: 1% 0;
}

.cmyp-entry-msg-image-container{
    display: flex;
    flex-direction: column;
}
.cmyp-entry-msg-image-container img{
  width: 60%;
  margin: auto;
}

.cmyp-important-instructions-container{
    background-color: none;
    background: none;
}

.cmyp-gallery-container{
    display: flex;
    flex-wrap: wrap;
}


.cmyp-gallery-container img{
  width: 30%;
  margin:1% auto;
  transition: all .4s ease;

}
.cmyp-gallery-container img:hover{
 scale: 1.2;
box-shadow: 0 0 9px 4px gray;
}