.logo-container-of-dpsru-ordinance{
    width: 20%;
    margin: auto;
}
.logo-container-of-dpsru-ordinance img{
    width: 100%;
    margin: auto;
}

.examination-ordinance-para-container {
    width: 65%;
    margin: auto;
    text-align: justify;
    color: #2020b5;
    font-weight: bold;
    font-family: cursive;
}
.examination-ordinance-para-container ul {
        margin: 3% 0;
        font-size: 1.4rem;
        line-height: 34px;
    }
.examination-ordinance-para-container ul li a {
        color: black;
        cursor: pointer;
        text-decoration: none;
    }
.examination-ordinance-para-container ul li a:hover {
        color: rgb(255, 0, 0);
        cursor: pointer;
    }