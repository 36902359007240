*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.vice-chancellor-full-component{
    width: 80vw;
    margin: auto;
}

.vc-comp-heading{
    background-color: #003366;
    color: wheat;
    text-align: center;
    margin:3% auto;
    padding: 3px 13px;
}

.vc-img{
    display: flex;
    margin: auto;
}
.vc-img-container {
    width: 20%;
    box-shadow: 1px 1px 11px 0;
}
.vc-intro{
    display: flex;
    margin: 5% auto;
    justify-content: space-around;
}

 .vc-message-full{
    font-size: 1.3rem;
    line-height: 173%;
    margin: 3% auto;
    text-align: justify;
}

.sign-container{
    text-align: right;
}

.chancellor-and-vice-chancellor-image-container img{
width: 20%;
border: none;
box-shadow: 0px 0px 4px 4px gray;
}

.chancellor-sign-container-img img{
    width: 20%;
    height: 15%;
}
.original-copy-of-vc-and-chancellor-msg{
    display: flex;
    justify-content: space-around;
}
.original-copy-of-vc-and-chancellor-msg a{
    font-size: 1.2rem;
}
.original-copy-of-vc-and-chancellor-msg a:hover{
color: red;
}

.vc-message-full{
    text-transform: none;
}

.sing-and-read-more-vice-chancelor-container{
    display: flex;
    justify-content: space-between;
}

/* Responsive Media query */
@media only screen and (max-width: 600px)  {
    .chancellor-and-vice-chancellor-image-container img {
        width: 62%;
        border: none;
        box-shadow: 0px 0px 4px 4px grey;
    }
}