*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.dipsar-sub-heading {
    margin: 2% auto;
    text-align: center;
    background-color: #000000a8;
    color: rgb(255 206 28);
    padding: 3px 2px;
    width: 33%;
    box-shadow: 2px 2px 4px grey;
}

.dipsar-paragrauph{
    text-align: justify;
    font-size: 1.2rem;
    line-height: 30px;
    text-transform: none;
}

.program-heading{
    margin: 2%;
    text-align: center;
    background-color:rgb(164 182 253); 
    color: rgb(0, 0, 0);
    padding: 6px 2px;
    text-transform: none;
}
.undergradute-program-box{
    line-height: 30px;
    font-size: 1.3rem;
}
.undergradute-program-box-main-heading-text{
    font-weight: bold;
    margin: 4px 0;
    text-decoration: underline;
    text-transform: none;
}
ul li{
    text-transform: none;
}

.post-graduation-box ul{
   line-height: 30px;

}
.post-graduation-box ul li{
    color: rgb(33, 33, 33);
    margin: 3px 11px;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: none;
}
.post-graduation-box ul li ol li{
    color: rgb(33, 33, 33);
    margin: 3px 11px;
    font-size: 1.1rem;
    font-weight: normal;
    text-transform: none;
}

/* Faculty */

.each-faculty-box-navlink-container{
    text-decoration: none;
}
.each-faculty-box{
    display: flex;
    margin: 2% auto;
    justify-content: space-around;
}

.each-faculty-img img{
    width: 147px;
    height: 154px;
}
.each-faculty-name-and-post{
    width: 70%;
    margin: 0% 5%;
}
.faculty-name{
    font-size: 1.7rem;
    margin: 15px 3px;
    color: black;
    text-transform: none;
}
.faculty-position {
    background: #e6e6e694;
    padding: 16px 7px;
    color: #626262;
}

.dipar-upper-navigation-menu-container{
    display: flex;
    margin: 2% auto;
    justify-content: space-between;
}
.dipar-upper-navigation-menu-container a {
    width: 15%;
    /* background-color: #181818; */
    color: #09125a;
    padding: 9px 6px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 9px;
    box-shadow: 3px 5px 7px 0px gray;
    border: #181818;
    transition: all .2s ease;
    text-decoration: none;
    text-align: center;
    text-transform: none;
}
.dipar-upper-navigation-menu-container a:hover {
   
    color: #ff2600;

}
.program-container a:hover{
    color: #ff2600;
}

@media only screen and (max-width: 600px) {

    .dipsar-heading {
        font-size: 1rem;
        background-color: #3737a9;
        padding: 2% 0;
    }

    .dipsar-sub-heading {
        margin: 8% auto;
        text-align: center;
        background-color: #1818f3a8;
        color: rgb(255, 255, 255);
        padding: 3px 2px;
        width: 64%;
    }
    .dipsar-paragrauph {
        text-align: justify;
        font-size: 1rem;
        line-height: 24px;
    }
    .undergradute-program-box-main-heading-text {
        font-weight: bold;
        margin: 2px 0;
        text-decoration: underline;
        font-size: 1.1rem;
        text-transform: none;
    }
    .undergradute-program-box{
        line-height: 25px;
        font-size: 1rem;
    }
    .post-graduation-box ul li ol li{
        color: rgb(33, 33, 33);
        margin: 3px 11px;
        font-size: .95rem;
        font-weight: normal;
    }

    .post-graduation-box ul{
        color: rgb(33, 33, 33);
        margin: 3px 11px;
        font-size: 1rem;
        line-height: 25px;
    }
    .post-graduation-box ul li{
        color: rgb(33, 33, 33);
        margin: 3px 11px;
        font-size: 1.1rem;
        font-weight: bold;
        text-transform: none;
    }
    .program-heading {
        margin: 6%;
        text-align: center;
        background-color: rgb(164 182 253);
        color: rgb(0, 0, 0);
        padding: 6px 2px;
        margin: 4% auto;
        font-size: 1.1rem;
        text-transform: none;
    }
    .each-faculty-box {
        display: flex;
        flex-direction: column;
        margin: 3% auto;
    }
    .each-faculty-img img {
        width: 134px;
        height: 141px;
        display: block;
        margin: auto;
    }
    .each-faculty-name-and-post {
        width: 96%;
        margin: 0% auto;
        text-align: center;
        text-transform: none;
    }
    .dipar-upper-navigation-menu-container {
        display: flex;
        margin: 2% auto;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .dipar-upper-navigation-menu-container a {
        width: 101%;
        /* background-color: #181818; */
        color: #09125a;
        padding: 8px 6px;
        font-size: 1.2rem;
        font-weight: 600;
        border-radius: 9px;
        box-shadow: 3px 5px 7px 0px gray;
        border: #181818;
        transition: all .2s ease;
        text-decoration: none;
        text-align: center;
        margin: 1% auto;
        text-transform: none;
    }
}
