.national-science-day-image-container{
    text-align: center;
}
.national-science-day-image-container img{
    width: 65%;
    margin: 3% auto;
    transition: all 1s ease;
    box-shadow: 3px 9px 13px 2px rgb(54, 54, 54);
}
.national-science-day-image-container img:hover{
    width: 70%;
   box-shadow: 0 0 16px 3px gray;
}