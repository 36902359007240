* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.youtube-link-of-footer-dpsru {
    width: 80%;
    margin: auto;
}

.youtube-link-heading {
    width: 80%;
    margin: 3% auto;
    background-color: red;
    color: white;
    padding: 4px 0;
    border-radius: 6px;
    text-align: center;
    font-size: 1.8rem;
}

.youtube-link-sub-heading {
    width: 60%;
    margin: 3% auto;
    background: radial-gradient(circle at center, #535252 0%, #858585 50%, #ffffff 100%);
    color: rgb(255, 252, 252);
    font-weight: bold;
    padding: 4px 0;
    border-radius: 6px;
    text-align: center;
    font-size: 1.6rem;
}

.youtube-link-youtube-logo {
    border-radius: 16px;
    padding: 22px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin: 2% auto;
    font-size: 4rem;
    outline: none;
    color: red;
    background: #f7f5f5;
    /* border: 1px solid gray; */
}

.links-container-youtube-dpsru ul li {
    margin: 2% 0;
    list-style: none;
}

.links-container-youtube-dpsru ul li a {
    font-size: 1.2rem;
    text-decoration: none;
    background: -webkit-linear-gradient(#000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-weight: bold;
    padding: 8px 18px;
    border-radius: 8px;
}

.links-container-youtube-dpsru ul li a:hover {

    background: -webkit-linear-gradient(#ff0000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* Responsive code Media query */

@media only screen and (max-width: 600px) {
    .youtube-link-youtube-logo {
        border-radius: 16px;
        padding: 15px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 2% auto;
        font-size: 4rem;
        outline: none;
        color: red;
        background: #f7f5f5;
        /* border: 1px solid gray; */
    }

    .youtube-link-heading {
        width: 90%;
    }
    .youtube-link-sub-heading {
        width: 91%;
        margin: 3% auto;
        background: radial-gradient(circle at center, #6b6b6b 0%, #b7b7b7 50%, #ffffff 100%);
        color: red;
        font-weight: bold;
        padding: 4px 0;
        border-radius: 6px;
        text-align: center;
        font-size: 1rem;
    }

    .links-container-youtube-dpsru ul li a {
        font-size: .8rem;
       
        padding: 8px 0px;
      
    }
    .links-container-youtube-dpsru ul li {
      margin: 4% auto;
      padding: 1%;
      background:linear-gradient(180deg,transparent, rgb(223, 222, 222));
      
    }
}