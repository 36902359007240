.peerTeamVisit-galary{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.gallery-item-peerTeamVisit{
    width: 30%;
    margin:2% 1%;
}
.gallery-item-peerTeamVisit a{
    width: 100%;
}
.gallery-item-peerTeamVisit a img{
    width: 100%;
    transition: all .9s ease;
}
.gallery-item-peerTeamVisit a img:hover {
    transform:scale(1.2);
    box-shadow: 1px 1px 7px 3px rgb(0, 0, 0);
  }

  .peer-team-heading{
    z-index: 999;
  
  }
  
  @media only screen and (max-width: 600px){
    .peerTeamVisit-galary{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .gallery-item-peerTeamVisit{
        width: 80%;
        margin:2% 1%;
    }
  }
  