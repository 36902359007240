*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.approval-component{
    width: 100vw;
}

.approval-heading{
    padding: 6px;
    color: white;
    background-color: blue;
    font-size: 2rem;
    text-align: center;
    margin: 3% auto;
    width:40%;
}

.approval-container{
    width: 53%;
    margin: auto;
}
.approval-container ul{
    line-height: 200%;
}

.approval-container ul li a{
    padding: 3px 13px;
    color: #333;
    text-decoration: none;
    font-size: 1.3rem;
    transition: all .2s ease;
}
.approval-container ul li a:hover{
    color: #ff0000;
  
}



/* Media Query */
@media only screen and (max-width: 480px) and (min-width: 320px) {

    .approval-heading {
        padding: 6px;
        color: white;
        background-color: #1e1e58;
        font-size: 2rem;
        text-align: center;
        margin: 3% auto;
        width: 91%;
        box-shadow: 2px 2px 5px grey;
    }
    .approval-container {
        width: 71%;
        margin: auto;
    }
    .approval-container ul li a {
        padding: 3px 13px;
        color: #333;
        text-decoration: none;
        font-size: 1.1rem;
    }
}

