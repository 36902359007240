*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.annual-report-component {
    /* width: 80%; */
    margin: auto;
    margin-top: 63px;
}


.annual-report-container {
    width: 129%;
}

.annual-report-heading {
    font-size: 3rem;
    color: #333333;
    margin: 4% 0;
    background-color: blue;
    color: white;
    width: 100%;
    padding: 1px 119px;
}

.annual-report-content-box {
    margin: 4% 0;
    line-height: 280%;
}

.annual-report-content-box p a {
    color: #333333;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
}
.annual-report-content-box p a:hover {
    text-decoration: underline;
    color: #000;
    font-family: 'Times New Roman', Times, serif;
}

.a-r-upper-container{
    display: flex;
}


/* Responsive css media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .annual-report-component {
        width: 94%;
        margin: auto;
        margin-top: 16px;
    }
    .annual-report-container {
        width: 93%;
        margin: auto;
    }
    .annual-report-heading {
        font-size: 2rem;
        color: #333333;
        margin: 2% 0;
        background-color: rgb(19, 19, 118);
        color: white;
        width: 88%;
        padding: 3px 0px;
        text-align: center;
        margin: auto;
    }
    .annual-report-content-box {
        margin: 4% 27px;
        line-height: 269%;
    }
}


