.p-c-each-img-container {
    text-align: center;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4% auto;
    transition: all .3s ease;
}
.p-c-each-img-container:hover {
    box-shadow: 0 0 7px 6px gray;
}
.p-c-each-img-container img {
    width: 210px;
    height: 200px;
}
.planning-committee-text-container {
    background-color: #1c1c1c;
    padding: 1px 18px;
    color: white;
}
.p-c-text-container-for-head {
    background-color: rgb(24 24 24);
    color: #f3c103;
    padding: 2px 91px;
}
.planning-committee-img-name{
    font-size: 1.4rem;
    font-weight: bold;
}

.planning-committee-img-designation{
    font-weight: 1.2rem;
    font-weight: bold;
}

.planning-committee-img-container{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}
.planning-committee-img-container .p-c-each-img-container{
   width: 31%;
}

/* Responsive css for mobile view */
@media only screen and (max-width: 600px){
    .planning-committee-img-container .p-c-each-img-container {
        width: 93%;
    }
    .p-c-text-container-for-head {
        background-color: rgb(24 24 24);
        color: #f3c103;
        padding: 2px 45px;
    }
}