@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.redesign-upper-navigation-quick-link-container {
  font-family: "Poppins", sans-serif;
}

.redesign-upper-nav-visiable-container-with-button {
  display: flex;
  justify-content: space-between;
  padding: 6px 24px;
  background-color: #004274;
  font-family: "Poppins", sans-serif;
}
.redesign-other-details-in-upper-menu-ul {
  list-style: none;
  display: flex;
}
.redesign-other-details-in-upper-menu-li {
  margin: 0 0.3rem;
  color: #c7e2fb;
  font-family: "Poppins", sans-serif;
}
.redesign-other-details-in-upper-menu-li a {
  margin: 0 0.3rem;
  color: #c7e2fb;
  font-family: "Poppins", sans-serif;
}
.redesign-other-details-in-upper-menu-Navlink {
  color: #c7e2fb;
}
.redesign-other-details-in-upper-menu-Navlink:hover,
.redesign-other-details-in-upper-menu-li a:hover {
  color: #fff;
  cursor: pointer;
}
.redesign-button-of-menu-in-upper-navigation {
  background-color: transparent;
  outline: none;
  border: none;
  color: #ffffff;
  padding-left: 2.5rem;
  font-size: 1.25rem;
  display: flex;
  transition: all 0.4s ease;
}
.redesign-button-of-menu-in-upper-navigation:hover {
  color: #fff;
  cursor: pointer;
}
.redesign-button-in-upper-nav-text-box {
  margin: 0px 6px;
  font-family: "Poppins", sans-serif;
}
.phone-redesign-phone-icon {
  color: #a5d4ff;
  margin: -2px 3px;
}
.redesign-button-in-upper-nav-icon-box {
  margin-top: 5px;
}
.links-container-for-redesign-uppernavigation-dpsru {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  background-color: #03477b;
/* border-radius: 16px; */
/* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
display: flex;
/* border: 1px solid rgba(255, 255, 255, 0.3); */
}


.link-container-and-addition-information-for-university-re-design-upper-nav-container01{
  width: 50%;
}
.link-container-and-addition-information-for-university-re-design-upper-nav-container02{
  width: 50%;
  display: flex;
  align-items: center;
  color: gray;
  /* justify-content: center; */
}

.links-container-for-redesign-uppernavigation-dpsru.open {
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
  max-height: 100vh; /* Adjust the max-height as needed */
}

.redesign-ul-second-container-for-addintional-information li a{
color: rgb(186, 186, 186);
text-decoration: none;
transition: all .4s ease;
}
.redesign-ul-second-container-for-addintional-information li{
list-style:none;
}
.redesign-ul-second-container-for-addintional-information li a:hover{
color: rgb(255, 255, 255);
}
.upper-navigation-quick-link-li {
list-style: none;
padding: 6px 0;
}
.upper-navigation-quick-link-li a {
  color: #a5d4ff;
  margin-left: 3.25rem;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.upper-navigation-quick-link-li a:hover {
  color: #ffffff;
}


/* Responsive for mobile phone */
@media only screen and (max-width: 600px){
  .redesign-upper-nav-visiable-container-with-button {
    padding: 4px 0px;
}
.redesign-responsive-li-which-should-be-hidden-in-mobile-view{
  display: none;
}
.redesign-button-of-menu-in-upper-navigation {
  padding-left: 1.5rem;
}
}