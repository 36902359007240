.container-of-video-library {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.video-container-library{
    width: 45%;
    margin:2% auto;
    height: 280px;
    /* box-shadow: 0 0 6px 3px gray; */
    transition: all .2s ease-in;
    position: relative;
}
.video-container-library:hover{
    box-shadow: 2px 2px 12px 5px gray;   
}
.video-container-library iframe{
   /* border: 4px solid var(--fontColor); */
   border: 4px outset #937f7f5e;
}