@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Raleway+Dots&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: none;
}



/* Redesigning home extra css */
.re-design-home-comp-img-gallary-front{
    width: 100%;
}
.re-design-home-comp-img-gallary-front img{
    width: 100%;
    
}





/* Redesigning home extra css */

/* .home-full-component {
    display: flex;
    width: 100vw;
} */

.sidenav-comp-home {
    width: 18%;
    background-color: var(--primaryBlue);
    padding-top: 2%;
    /* height: 100vh; */
}

.home-component {
    margin: auto;
    text-transform: none;
}

.upper-cont-comp {
    margin: auto;
    background: url('../Logo/chanslor-bg.jpg')no-repeat center center fixed;
    background-color: rgba(255, 255, 255, 0.919);
    /* Set the opacity of the background color to 91% */
    background-blend-mode: lighten;
    text-transform: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;


}



.vc-container-data {
    display: flex;
}

.photos-comp {
    width: 26%;
    /* height: 45vh; */
    /* border: 1px solid black; */
}

.img-heading {
    background-color:  var(--primaryBlue);
    color: white;
    text-align: center;
    padding: 14px;
    width: 30%;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 0;
    position: relative;
    left: 11%;
    text-transform: none;
}


.vc-text-area {
    width: 70%;
    margin-top: 3%;
    font-size: 1.1rem;
    line-height: 152%;
    /* height: 40vh; */
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    padding: 0 1%;
    text-transform: none;
    flex-direction: column;
}

.vc-message {
    text-align: justify;
    text-transform: none;
}

.read-more-vc-message {

    background-color:  var(--primaryBlue);
    color: white;
    padding: 3px 13px;
    border: 1px solid #196fc6;
    text-decoration: none;
    border-radius: 3px;
    text-transform: none;
}

.read-more-vc-message:hover {

    background-color: #0251a1;
    box-shadow: 2px 2px 4px #333;
}

.vc-img {
    border: 1px solid  var(--primaryBlue);
    height: 55%;
    width: 50%;

}

.vc-intro {
    text-align: center;
    text-transform: none;
}

.box-heading {
    text-align: center;
    background-color: var(--primaryBlue);
    color: white;
    padding: 10px 0;
    font-size: 16px;
    text-transform: none;
}

.info-box {
    width: 44%;
    padding: 0 8px;
    text-transform: none;
}

.mission-and-vission-container {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* border-left: 1px dotted blue; */
    text-transform: none;
}

.mission-container,
.vission-container {
    width: 43vw;
    /* border: 1px dotted blue; */
    padding: 13px;
    text-transform: none;
}

/* .vission-container{
border-left: 1px dotted blue;
} */

.mission-heading {
    background-color: var(--primaryBlue);
    color: white;
    text-align: center;
    padding: 3px 13px;
    width: 41%;
    margin: 3% auto;
    margin-bottom: 0;
    font-size: 1.4rem;

}

.mission-text {
    margin-top: 3%;
    line-height: 25px;
    text-align: justify;
    text-transform: none;
}

.vision-for-viksit-bharat-home-page-container{
    width: 43vw;
    /* margin: auto; */
}

/* Notification area */
.see-all-notification-in-marquee-tab{
    /* background: #003366; */
    background:#cf261f;
    width: 10%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
}
.see-all-notification-in-marquee-tab:hover{
    background: #0064c7;
  

 
}
.marquee-bulletin-area {
    width: 100%;
    border-top: 1px solid  var(--primaryBlue);
    border-bottom: 1px solid  var(--primaryBlue);
    display: flex;
    margin: 0% auto;
    /* background-color: rgb(229, 228, 228); */
    /* margin-bottom: 1%; */
   /* background:  linear-gradient(0deg, #c7c7c7, transparent); */
   text-transform: none;
}

.bulletain-text {
    color: white;
    background-color:  var(--primaryBlue);
    padding: 4px 2px;
    width: 10%;
    text-align: center;
    margin: auto;
    text-transform: none;
    font-size: 1.1rem;
    /* border-radius: 100% 0% 0% 100% / 50% 100% 0% 50%; */
    /* border-radius: 77%; */
}

.marquee-notification {
    text-transform: none;
    margin: auto;
    color: red;
    font-weight: bold;
    padding: 5px 0;
   
}

.marquee-notification a {
    color: rgb(244, 9, 9);
    font-family: 'DotGothic16', sans-serif;
    /* text-shadow: 0px 0px 5px red; */
    letter-spacing: 2px;
    font-size: 1.1rem;
    text-decoration: none;
    text-transform: none;
    
}
.marquee-notification a span{
    text-transform: none;
    
}

.marquee-notification a:hover {
    color: rgb(0, 0, 0);
}

.newsletters {
    width: 1117px;
    height: 320px;
    border: 3px solid  var(--primaryBlue);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    text-transform: none;
}

.heading-text-newsletters {
    color: red;
    /* font-weight: bold; */
    font-size: 2rem;
    text-align: center;
    text-decoration: underline;
    border: 1px solid gray;
}


.marquee-newsletters a {
    color: red;
    padding: 0 10%;
    font-weight: bold;

}

/* Third section */

.third-section {
    display: flex;
    width: 95%;
    margin: auto;
    justify-content: space-around;
    margin-top: 2%;
}

.third-sec-box {
    width: 30%;
    line-height: 2;
}

.readmore-home-component-btn-resp {

    /* style={{textAlign:'right', color: 'blue', fontSize: '1.2rem' }} */
    color: blue;
    font-weight: bold;
    text-align: right;
}

.third-sec-heading {
    padding: 6px;
    color: #ffffff;
    background-color: #161665;
    font-size: 1.2rem;
    background: linear-gradient( 0deg,#161665, #2a2ab4);
    text-align: center;
    box-shadow: 3px 3px 3px 1px grey;
    text-shadow: 2px 2px 2px black;
}
.date-of-third-sec {
    font-size: 13px;
    color: #196fc6;
    font-style: italic;
    padding: 0;
    font-weight: 600;
    margin: 0 !important;
}

.third-sec-box a {
    color: #222;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
}

/* iastam */
.home-iastam-news-container {
    background-color: rgba(236, 236, 242, 0.565);
}

.home-iastam-news-container ul {
    line-height: 26px;
}

.home-iastam-news-container ul li {
    background-color: #24415dcf;
    color: white;
    line-height: 20px;
    margin: 5px 0;
}

.home-iastam-news-container ul li:hover {
    background-color: #003366cf;

}

.home-iastam-news-container ul li a {
    color: white;
    padding: 2px;
}


/* Responsive blocking css */
.responsive-home-side-nav-for-small-screen {
    display: none;
}


/* changing on 3rd march 2023 */
.sidenav-and-chancellor-flex-box-new {
    display: flex;
    
}

.mission-vision-and-aboutus-container-home-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-us-home-page-container {
    width: 43vw;
    padding: 13px;
}

.read-more-btn-for-about-us-home-page {
    display: block;
    margin: 4% auto;
    width: 25%;
    padding: 4px;
    text-align: center;

}

.devider-of-marquee-noti-home{
margin: 0 1%;
color: rgb(0, 8, 255);
}

/* Event page on home page */
.dpsru-event-page-on-home-page{
    height: 40vh;
  
    margin: auto;
    border: 2px solid var(--primaryBlue);
    scroll-behavior: smooth;
    overflow-x: scroll;

}
.viksit-bharat-and-recent-event-section-on-home-page{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.Recent-event-at-home-page-dpsru{
    /* border: 2px solid rebeccapurple; */
    width: 43vw;
    padding: 13px;
}
.event-class-at-home-dpsru{
    /* border: 2px solid red; */
    padding: 5px 4px;
    margin: 2% auto;
    text-align: center;
    list-style-type: square;
    height: 380px;
}
.event-li-at-home-page{
margin: 36px 0;
text-align: start;
}
.event-ul-at-home-page li a{
margin: 15px 0;
color: red;
}
.event-ul-at-home-page{
    width: 85%;
    margin: auto;
}
