@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Roboto+Mono&family=Rowdies&family=Rubik+Mono+One&family=Rye&family=Yuji+Boku&display=swap');
.chintan-sivir-video-container{
    width: fit-content;
    margin: auto;
}

.mark-calender-chintan-shivir-p {
    color: #de3163;
    font-family: "Rubik Mono One", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 2% auto;
    width: 80%;
}

.intro-chintan-sivir {
    text-align: center;
    font-family: "Rowdies", Sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: #800080;
    font-weight: bold;
    margin: 2% auto;
}

.intro-text-of-chintan{
    color: #008000;
    text-align: justify;
    font-family: "Rye", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin: 2% auto;
    line-height: 30px;
}

.what-is-chintan-shivir-about{
    font-family: "Rowdies", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 2% auto;
    color: black;
    width: 80%;
    margin: auto;
}

.bring-together-text{
font-family: "Roboto", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin:2% auto;
    color: #ff00ff;
    line-height: 30px;
}


.bring-together-container ul li{
    font-family: "Roboto", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin:.5% auto;
    color: #ff00ff;
    line-height: 30px;
}

.bring-together-text-2{
    font-family: "Roboto", Sans-serif;
        font-size: 22px;
        font-weight: 400;
        width: 80%;
        margin:2% auto;
        color: #0035a6;
        line-height: 30px;
        font-weight: bold;
    }

    .p-no-5th{
        color: #33cccc;
        text-align: justify;
    font-family: "Roboto", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin: 2% auto;
    font-weight: bold;
    line-height: 32px;
    }
    .p-no-6th{
        color: #ff0000;
        text-align: justify;
    font-family: "Roboto", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin: 2% auto;
    font-weight: bold;
    line-height: 32px;
    }
    .p-no-7th{
        color: #0040ff;
        text-align: justify;
    font-family: "Roboto", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    margin: 2% auto;
    font-weight: bold;
    line-height: 32px;
    }