.information-as-be-prescribed-table-container{
    margin: 2% auto;
}
.information-as-be-prescribed-table-container table tr td{
   font-size: 1.2rem;
   line-height: 26px;
}
.information-as-be-prescribed-table-container a{
    text-transform: lowercase;
    color: black;
transition: all .2s ease;
}
.information-as-be-prescribed-table-container a:hover{
   color: red;
}