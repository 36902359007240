*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.conferences-component {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    /* margin-top: 5%; */
}
.conference-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
}

.conference-oil {
    margin: 2% 0;
    color: #333;
}

.confercences-container p {
    margin: 5px 0;
    color: #444;
}
.confercences-container ul {
    list-style: none;
}
.confercences-container ul li {
    padding: 1% 0;
}


.confercences-container ul li a{
    /* color: #333; */
    font-size: 1.2rem;
    text-decoration: none;
}
.confercences-container ul li a:hover{
    color: #000;
    text-decoration: underline;
}