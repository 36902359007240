*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.newsletters-component{
    width: 100%;
    margin: auto;
    text-align: center;
}

.newsletters-container{
    margin: auto;
}
.newsletters-container p{
    padding: 10px;
}
.newsletters-container p a{
    font-size: 1.3rem;
    padding: 8px 18px;
    color: #272727;
    text-decoration-line: blink;
    transition: all .2s ease;
}
.newsletters-container p a:hover{
    color: #ff0404;
}
.newsletters-heading {
    padding: 6px;
    color: white;
    background-color: blue;
    font-size: 1.5rem;
    text-align: center;
    margin: 3% auto;
    width: 80%;

}



/* Responsive Media Query */
@media only screen and (max-width: 480px) and (min-width: 320px) {

    .newsletters-heading {
        padding: 6px;
        color: white;
        background-color: #17173f;
        font-size: 1.2rem;
        text-align: center;
        margin: 3% auto;
        width: 82%;
    }
    .newsletters-container p {
        padding: 10px;
        text-align: left;
        border: 1px solid gray;
        margin: 1% 0;
    }
    .newsletters-container {
        margin: auto;
        width: 80%;
    }
    .newsletters-container p a {
        font-size: 1.1rem;
       
    }
    
    
}