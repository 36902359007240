*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}
.academics-component-first-container{
    width: 100%;
}
.academics-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
}

.academics-component{
    width: 80%;
    margin: auto;
    background-color: rgba(133, 189, 229, 0.207);
    display: flex;
    padding: 3%;
    justify-content: space-around;
}
.academics-container-1{
    text-align: justify;
    line-height: 38px;
    font-size: 1.2rem;
}
.academics-container-2{
    text-align: justify;
    line-height: 38px;
    font-size: 1.2rem;
}
.academics-component a{
    color: rgb(29, 10, 238);
    text-decoration: none;
    padding: 2px 11px;
    border-radius: 6px;
}
.academics-component a:hover{
   color: red;
   text-decoration: underline;
}
.displayNoneForBigScreen{
    display: none;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .academics-component {
        display: flex;
        flex-direction: column;
        padding: 3%;
        justify-content: space-around;
    }
    .academics-container-1 {
        text-align: justify;
        line-height: 28px;
        font-size: 1rem;
        padding: 0 8px;
    }
    .academics-container-2 {
        /* text-align: justify; */
        padding: 0 8px;
        line-height: 18px;
        font-size: 1rem;
    }
    .displayNoneForBigScreen{
        display: initial;
        margin: 3% 0;
    }
}