*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: none;
}

.organization-and-function a{
    color: rgb(35, 87, 255);
    text-decoration: none;
    
}


