* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iaqc-feedback-inner-container {
    width: 80%;
    display: block;
    margin: auto;
}

.iaqc-feedback-inner-container ol li {
    margin: 1% 0;
    padding: 6px 0;
}

.iaqc-feedback-inner-container ol li a {
    font-size: 1.2rem;
    padding: 5px 15px;
    border-radius: 7px;
    color: #000;
    /* box-shadow: -1px 1px 1px gray; */
    text-decoration: none;
    position: relative;
}

.iaqc-feedback-inner-container ol li a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0a22f9;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.iaqc-feedback-inner-container ol li a:hover::before {
    visibility: visible;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .iaqc-intro-sub-heading {
        font-size: 1rem;
    }
}