.about-viksit-bharat-paragrauph {
    font-size: 1.2rem;
    line-height: 30px;
    text-align: justify;
}

.general-heading-for-viksit-bharat{
    color: #2c498d;
    margin-bottom: 2%;
    text-decoration: underline;
}