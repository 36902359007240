.new-vc-page-paragrauph{
    padding: 2% 4%;
    font-size: 1.1rem;
    line-height: 29px;
}
.new-vc-details-text-container {
    background: #f4f7ff;
    text-align: justify;
    color: #252525;
}
.ul-of-new-vc-page-details{
    width: 90%;
    margin: auto;
}
.new-vc-sir-contact-details-box{
    line-height: 26px;
}
.new-vc-sir-contact-details-box p{
 color: gray;
}