*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iaqc-mom-image-container{
    width: 100%;
}
.iaqc-mom-image-container img{
    width: 90%;
    margin: auto;
    display: block;
    box-shadow: 1px 1px 4px 4px gray;
}

.composition-and-mom-iaqc-container{
    width: 80%;
    margin: auto;
}
.composition-and-mom-iaqc-container ul{
   list-style: none;
}
.composition-and-mom-iaqc-container ul li{
   margin: 2% 0;
}
.composition-and-mom-iaqc-container ul li a{
  padding: 5px 15px;
  color: black;
  text-decoration: none;
  font-size: 1.1rem;
  border-radius: 5px;
}
.composition-and-mom-iaqc-container ul li a:hover{
 background: linear-gradient(0deg, rgb(194, 194, 194),transparent);
 border-bottom: 1px solid gray;
 color: rgb(14, 42, 251);

}

@media only screen and (max-width: 600px) {
    .composition-and-mom-iaqc-container ul li {
        margin: 8% 0;
        border: 1px solid black;
        padding: 2% .8%;
    }
    .composition-and-mom-iaqc-container ul li a {
        font-size: .8rem;
        padding: 5px 15px;
    }
   
}