.admin-component {
  /* background: url("./bglogin.jpg") no-repeat center center/cover; */
}
.admin-heading-new {
  background: none;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 32px rgb(83 83 83 / 69%);
  backdrop-filter: blur(60.8px);
  -webkit-backdrop-filter: blur(3.8px);
  /* border: 1px solid rgba(255, 255, 255, 0.24); */
  border-radius: none;

  text-align: center;
  font-size: 2rem;
  margin: 10px auto;
  margin-top: 33px;
  color: #4fedfd;;
  font-family: 'Times New Roman', Times, serif;
  padding: 5px;
  letter-spacing: 8px;
}
