*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contactus-component{
   width: 80%;
    margin: auto;
}

.contactus-container{
    margin: auto;
    margin-top: 7%;
}

.contactus-heading {
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: #15214e;
    padding: 3px 0;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
}

.contactus-text{
    margin-top: 7%;
}

.heading-text-contact-us{
    background-color: #5959cb;
    color: white;
    padding: 4px;
    text-align: center;
    width: 50%;
    margin-bottom: 3%;
    margin: auto;
    margin: 1% auto;
    font-weight: bold;

}
.contactus-details{
    font-size: 1.3rem;
    line-height: 199%;
    text-align: center;
}

.contact-us-note{
    color: red;
    margin: 2% auto;
    text-align: center;
}


/* Responsive media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .contactus-component {
        width: 96%;
        margin: auto;
    }
    .heading-text-contact-us {
        width: 90%;
        margin: 3% auto;
        font-weight: bold;
    }
    .contactus-details {
        font-size: 1rem;
        line-height: 199%;
        text-align: center;
    }
    .contact-us-note {
        color: red;
        margin: 2% auto;
        text-align: center;
        font-size: 1.3rem;
    }
}