.student-component-container{
    display: flex;
    justify-content: space-between;
}

.students-first-container{
    width: 60%;
    background-color: none;
    background: none;
}

.student-section-second-container{
    width: 39%;
}

.strikingOfRolls-button {
    color: #004fb1;
    background: #d5d5d5;
    padding: 7px 11px;
    border: 1px solid #004fb1;
    border-radius: 8px;
    font-weight: bold;
    /* color: #f2c007; */
    letter-spacing: 1px;
}

@media only screen and (max-width: 600px){
    .student-component-container {
        display: flex
    ;
        justify-content: space-between;
        flex-direction: column;
    }
    .students-first-container {
        width: 96%;
        background-color: none;
        background: none;
        margin: auto;
    }
    .student-section-second-container {
        width: 95%;
        margin: auto;
    }
}