.redesign-home-administrative-container{
    display: flex;
    justify-content: space-around;
}
.each-chancelor-and-vc-container-for-redesign {
  width: 30%;
  /* border: 3px solid #d9f4fd; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  line-height: 1.2;
  box-shadow: #d9f4fd 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  overflow: hidden;
  height: 520px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.each-chancelor-and-vc-container-for-redesign:hover{
  box-shadow: 0 0 5px #02a5dc;
}

.ReDesign-heading-of-chanslor-and-vc-container
{
    width: 90%;
    margin:2% auto;
    left: 0;
    margin-bottom: 0%;
}
.seprator-redesign-for-chancelor{
  width: 300px;
  height: 31px;
}
.seprator-redesign-for-viksit-bharat-heading {
  width: 372px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  left: 10px;
}
.redesing-chancellor-and-vc-container-data-inner {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  padding: 2px;
  /* border-radius: 22px; */
  color: #1b1a1a;
  overflow: hidden;
  height: 100%;
  box-shadow: #d9f4fd 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
/* 
.each-chancelor-and-vc-container-for-redesign:hover {
    transform: translate(0px, -16px);
  }
   */
  /* .each-chancelor-and-vc-container-for-redesign:hover .redesing-chancellor-and-vc-container-data-inner::before {
    rotate: -8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .each-chancelor-and-vc-container-for-redesign:hover .redesing-chancellor-and-vc-container-data-inner::after {
    rotate: 8deg;
    top: 0;
    width: 100%;
    height: 100%;
  }
   */


/* .redesing-chancellor-and-vc-container-data-inner::before {
    position: absolute;
    content: "";
    top: -4%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%);
    background: #ced8ff;
    z-index: -1;
    transform-origin: bottom;
  
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  } */
  
  /* .redesing-chancellor-and-vc-container-data-inner::after {
    position: absolute;
    content: "";
    top: -8%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%);
    background: #e7ecff;
    z-index: -2;
    transform-origin: bottom;
    border-radius: inherit;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  } */

.Redesign-chancellor-and-vc-photo-comp{
    width: 100%;
    height: 25vh;
}
.redesign-vc-and-chancellor-text-box-area{
    width:95%;
    margin: auto;
}
.vc-img-home-page-redesign-landing-page{
    border: 1px solid var(--primaryBlue);
    height: 75%;
    width: 50%;
    border-radius: 12px;
}
.Redesign-about-us-home-container{
  background-color: #fdf1f7;
  border-radius: 15px;
  padding: 2.5rem 5rem;
  text-align: center;
  display: block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  margin: 1rem 0;

}
.Redesign-about-us-home-container:hover{
  box-shadow: 0 0 8px #ff8fc7;
}
.Redesign-mission-home-conainer{
  background-color: #d9f4fd;
  border-radius: 15px;
  padding: 2.5rem 5rem;
  text-align: center;
  display: block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  margin: .4rem 0;

}
.Redesign-mission-home-conainer:hover{
  box-shadow: 0 0 5px #02a5dc;
}
.Redesign-mission-home-conainer-margin-top{
  margin-top: 1rem;
}
.Redesign-mission-heading-home-page{
  background: none;
  color: black;
  border: none;
  font-weight: bold;
  
}

.image-seprator-redesign{
  width: 150px;
  height: 20px;
}


.redesign-home-examination-and-admission-details-container{
  display: flex;
  margin: 2% auto;
  padding: 2%;
  ::-webkit-scrollbar {
    display: none;
  }

  box-shadow: #ff8fc7 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.container-for-examination-result-and-datesheet-for-redesign-home-page, .container-for-examination-result-and-datesheet-for-redesign-home-page-2{
  width: 25%;
 max-height: 400px;
 scroll-behavior: smooth;
 overflow: auto;
 

}
