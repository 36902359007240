*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iaqc-n-r-sub-heading{
    width: 50%;
    margin: auto;
    background-color: rgb(0, 36, 128);
    padding: 6px 60px;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 1.3rem;
    
}

@media only screen and (max-width: 600px)
{
    .campus-highlight-container-in-about-page figure {
        width: 22%;
        margin: 10% 0;
    }
    .campus-highlight-container-in-about-page figure figcaption {
        text-align: center;
        font-weight: bold;
        font-size: .6rem;
    }
}