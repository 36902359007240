
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.page-not-found-component{
   
 width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}


.text-404 {
    font-size: 4rem;
    font-family: 'Gloria Hallelujah', cursive;
   margin-top: 5%;
    text-shadow: 0px 0px 14px #ff0000;
}

.img-container-pnf img{
    width: 60%;
}
.look-like-u-r-lost {
    font-size: 3rem;
   /* margin-bottom: 2%; */
    text-shadow: 1px 1px 3px #39ad31;
    font-family: 'Gloria Hallelujah', cursive;
}

.pnf-text-paragrauph {
    font-size: 1rem;
    margin-bottom: 4%;
    text-shadow: 1px 1px 3px #ee9302;
}
.link-404-nav-link {
    font-size: 1.2rem;
    background: green;
    padding: 4px 8px;
    margin: 3% 0;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}