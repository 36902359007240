.redesign-take-a-tour-component {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .redesign-take-a-tour-component .redesign-background-take-a-tour-video-youtube {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Makes sure the iframe doesn't interfere with content interactions */
  }
  
  .re-design-take-a-tour-content-container {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    padding: 20px;
  }