@media only screen and (max-width: 480px) and (min-width: 320px) {

    .nav-text a {
        font-size: 11px;
    }
    .nav-text a span{
        margin-left: 6px;
        font-weight: bold;
    }

    
.sidenav-responsive-button {
    position: relative;
    top: 0px;
    width: 34px;
    height: 23px;
    margin-top: 5px;
    padding: 0;
    color: #000000;
    font-size: 1.7rem;
    background: none;
    border: none;
}
.sidenav-responsive-button:hover {
   color: black;
}

}
