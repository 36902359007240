.mision-vission-objectives-quality-each-sec-container{
    width: 80%;
    margin: 4% auto;
    text-align: justify;
    text-transform: none;
}
.mision-vission-objectives-quality-each-sec-container p{
  font-size: 1.3rem;
  line-height: 32px;
  text-align: justify;
  text-transform: none;

}
.mision-vission-objectives-quality-each-sec-container p span{
  font-weight: bold;
  text-transform: none;
}
.mision-vission-objectives-quality-each-sec-container p ol{
  margin: 3% 2%;
  text-transform: none;
}
.mision-vission-objectives-quality-each-sec-container p ol li{
  margin: 3% auto;
  text-transform: none;
}
.mision-vission-objectives-quality-each-sec-container p ol li::marker{
 font-weight: bold;
 color: red;
}

.quality-policy-container-in-ms-viss-page p{
margin: 2% 0;
text-align: justify;
text-transform: none;
}

.quality-policy-container-in-ms-viss-page ul{
line-height: 37px;
text-transform: none;
}
.quality-policy-container-in-ms-viss-page ul li a , .quality-policy-container-in-ms-viss-page ul li Link {
 font-size: 1.4rem;
 text-decoration: none;
 font-weight: bold;
 color: black;
 text-transform: none;
}

.quality-policy-container-in-ms-viss-page ul li a:hover , .quality-policy-container-in-ms-viss-page ul li Link:hover {
 color: rgb(255, 0, 0);
}