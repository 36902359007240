@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');


.library-paragrauph {
    text-align: justify;
    margin: 2% auto;
    width: 80%;
    line-height: 29px;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.link-container-of-libraury{
    width: 80%;
    margin: 2% auto;
}
.link-of-library-academics{
    font-size: 1.3rem;
    padding: 5px;
    border-radius: 7px;
    color: #0e2eff;
    /* box-shadow: -1px 1px 1px gray; */
    text-decoration: none;
    position: relative;
}

.link-of-library-academics::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f90a0a;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.link-of-library-academics:hover::before {
    visibility: visible;
    width: 100%;
}