/* @media only screen and (max-width: 480px) and (min-width: 320px) { */
@media only screen and (max-width: 600px) {
    .takeatour-component {
        padding: 5px;
        height: auto;
    }

    .takeatour-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .video-container {
        width: 87%;
    }
    .photos-highlight-container {
        width: 87%;
    }



}