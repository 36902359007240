.admin-logged-in-pages-heading{
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin: 1% auto;
    color: rgb(255, 255, 255);
    background: #161414;
    padding: .3% 0;
}


.data-container-of-graviance-form{
    display: flex;
    flex-direction: column-reverse;
}
.inner-data-of-get-gravience-form-admin {
    /* border: 2px solid red; */
    margin: 2% auto;
    padding: 2% 3%;
    background: none;
    box-shadow: 0 0 9px 3px gray;
    border-radius: 7px;
    transition: all .4s ease;
    width: 99%;
}
 
.inner-data-of-get-gravience-form-admin:hover {
    box-shadow: 0 0 13px 6px gray;
    width: 100%;
}
.inner-data-of-get-gravience-form-admin li{
    font-size: 1.2rem;
    margin: 1% 0;
    background-color: rgb(79 237 253 / 8%);
    padding: .5%;
    color: rgb(20, 18, 18);
}

.graviance-data-key-admin{
    font-weight: bold;
    margin-right: 1%;
}