*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.administration-component{
    width: 90%;
    margin: auto;
}

.administration-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
}
.administration-heading-sub{
    text-align: center;
    font-size:1.2rem;
    margin: 10px auto;
    margin-top: 33px;
    background: linear-gradient(45deg,orange,white,green);
    color: rgb(46 65 146);
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
    display: block;

    width: 43%;
}
.administration-heading-sub-2{
    text-align: center;
    font-size:1.2rem;
    margin: 10px auto;
    margin-top: 33px;
    color: white;
    background-color: rgb(46 65 146);
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
    display: block;

    width: 43%;
}

.img-box-of-administration{
    width: 100%;
    margin: 3% 0;
}
.img-box-of-administration img{
    width: 70%;
    margin: auto;
    display: block;
}

.ce-container-box{
    width: 45%;
    margin:3% auto;
}
.ce-container-box ul{
   line-height: 30px;
   font-size: 1.2rem;
   list-style-type: square;
}
.ce-container-box ul li{
  background:  rgb(239, 235, 246);
  margin: 6px 0;
  padding: 2px 4px;
  color: black;
}
.ce-container-box ul li a{
  color: black;
  text-decoration: none;
}
.ce-container-box ul li a:hover{
  color: rgb(0, 21, 255);
}

.ce-container-box ul li:hover{
  background: rgb(217, 217, 217);
}

@media only screen and (max-width: 480px) and (min-width: 320px) {


  /* Certificate course */
  .ce-container-box ul {
    line-height: 25px;
    font-size: 1rem;
    list-style-type: square;
}
.ce-container-box {
  width: 82%;
  margin: 7% auto;
}

/* Administration */
.administration-heading-sub {
  width: 88%;
}

.administration-heading-sub-2 {
  width: 57%;
}
.administration-gc-heading-sub-2 {
  font-size: 1.3rem;
}


}