*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iqac-policy-container ul li{
 margin: 1%  auto;
 width: 80%;
 display: block;
}

.iqac-policy-container ul li a{
 font-size: 1.2rem;
 color: black;
 text-decoration: none;
 position: relative;
}

.iqac-policy-container ul li a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0a22f9;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.iqac-policy-container ul li a:hover::before {
    visibility: visible;
    width: 100%;
}
