* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.assrm-main-paragrauph {
    font-size: 1.3rem;
    text-align: justify;
    padding: 2%;
    color: #000000;
    /* background: linear-gradient(180deg, #e4e4e4, transparent); */
    border-radius: 13px;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 30px;
    text-transform: none;

}

.our-vision-mission-box-assrm {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3% 0;
    text-transform: none;
}

.our-vision-mission-box-assrm p {
    text-align: justify;
    text-transform: none;
}

.our-vision-mission-inner-card-assrm {
    border: 2px solid black;
    width: 46%;
    background: #f8fdff;
    height: 155px;
    margin: 0px 12px;
    padding: 1%;
    text-transform: none;
}

.our-vision-mission-inner-card-assrm:hover {
    box-shadow: 5px 6px 5px grey;
}

.our-vision-mission-heading-assrm {
    /* background: #9292e8; */
    background: linear-gradient(45deg, #0c0c0d, transparent);
    padding: 1%;
    color: white;
    text-align: center;
    margin-bottom: 12px;
    border-bottom: 2px solid black;
}

.key-members-section-assrm {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    margin: auto;
    /* border: 2px solid blue; */
}

.key-members-container-assrm-each-img-container {
    width: 45%;
    align-items: center;
    margin: 4% auto;
    /* border: 2px solid red; */
}

.key-members-container-assrm-each-img-container a img {
    width: 70%;
    /* border: 2px solid yellow; */
    display: block;
    margin: auto;
}

.key-members-container-assrm-each-img-container a img:hover {
    box-shadow: 5px 5px 4px 2px rgb(170, 168, 168);
}

.key-members-name-assrm {
    text-align: center;
    margin: 1% 0;
}

.key-members-details-assrm {
    text-align: center;
}


.sub-heading-of-assrm {
    margin: 2% auto;
    text-align: center;
    background-color: #1818f3a8;
    color: rgb(255, 255, 255);
    padding: 3px 2px;
    width: 33%;
}

.program-container-assrm ul {
    font-size: 1.3rem;
    margin: 2% 0;
    padding: 2% 0;
    line-height: 33px;
}

.our-infrastacture-assrm ul {
    line-height: 34px;
    font-size: 1.3rem;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .assrm-main-paragrauph {
        font-size: 1rem;
        background: linear-gradient(0deg, #e4e4e4, transparent);
        line-height: 27px;
    }

    .our-vision-mission-box-assrm {
        width: 98%;
        display: flex;
        justify-content: center;
        margin: 7% 0;
        flex-direction: column;
    }

    .our-vision-mission-inner-card-assrm {
        border: 2px solid black;
        width: 90%;
        background: #f8fdff;
        margin: 9px auto;
        height: auto;
        padding: 2%;
    }

    .sub-heading-of-assrm {
        width: 85%;
    }

    .program-container-assrm ul li {
        font-size: 1rem;
        margin: 0 5%;
    }
    .key-members-section-assrm {
        display: flex;
        width: 92%;
        flex-wrap: wrap;
    }
    .key-members-container-assrm-each-img-container {
        width: 88%;
    }
    .our-infrastacture-assrm ul {
        line-height: 28px;
        font-size: 1rem;
        margin: 0 0%;
        margin-left: 9%;
    }
    .our-infrastacture-assrm ul li{
        margin: 5% 0;
    }
}