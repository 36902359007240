*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.takeatour-component{
    /* height: 63vh; */
    /* background-color: rgb(206, 196, 250); */
    background:url('../Logo/gif\ bg.gif') no-repeat center center/cover;
    /* background-color: #cccccc;  */
    /* filter: blur(8px);
  -webkit-filter: blur(8px); */
    /* height: 500px; You must set a specified height */
    /* background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;  */
    padding: 13px;
}

.takeatour-container{
    display: flex;
    justify-content: space-evenly;
}

.tat-heading-text {
    background-color: #0b6dcf;
    color: white;
    text-align: center;
    padding:  4px;
    /* width: 30%; */
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 0;
}
.video-container{
    width: 45%;
}
.video-container Iframe{
    width: 100%;
    height: 62%;
    outline: none;
    border: none;
}
.photos-highlight-container{
    width: 45%;
}
.photos-highlight-container img{
    width: 100%;
    height: 70%;
}