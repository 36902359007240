.archive-ul-container {
 
   width: 93%;
   margin: auto;
}
.archive-li-box {
   margin: 2% 0;
}
.archive-li-box a {
   color: #2d2d2d;
   text-decoration: none;
   font-weight: bold;
   transition: all .3s ease;
   width: 100%;
   padding: 12px;
   border-radius: 6px;
   text-align: center;
   transition: all .3s ease;
   /* background: #706868; */
}
.archive-li-box a:hover{
   color: rgb(153, 19, 19);
   /* background: #cabfbf; */
}
