*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}
.dc-component{
    width: 97%;
}

.dc-heading{
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
}

.dc-container{
    width: 40%;
    margin: auto;
    line-height: 33px;
}
.dc-container p{
   font-size: 1.3rem;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .dc-container {
        width: 77%;
        margin: auto;
        line-height: 33px;
    }
    .dc-container p{
        font-size: 1rem;
        margin-left: 9%;
    }
}