* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.disciplineRagging-component {
    width: 80%;
    margin: auto;
    text-align: justify;
}

.discipline-section {
    width: 80%;
    margin: auto;
    line-height: 30px;
    font-size: 1.2rem;
    color: #000;
}

.ragging-section ul li {
    margin: 1.5% 0;
}
.ragging-section ul li ul{
    margin-left: 4%;
}

