* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.rd-component {
  width: 80%;
  margin: auto;
}
.rd-heading {
  text-align: center;
  font-size: 2rem;
  margin: 10px;
  margin-top: 33px;
  background-color: rgb(16, 16, 229);
  color: white;
  box-shadow: 4px 5px 5px #3f3e18;
  padding: 4px 0;
}

.link-container-of-rd-component ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 3% auto;
}
.link-container-of-rd-component ul li {
  width: 30%;
  list-style: none;
  margin: 1% 0;
  text-align: center;
}

.link-of-rd-container {
  width: 100%;
  background-color: #181818;
  color: #e1b209;
  padding: 9px 0;
  font-size: 1.1rem;
  text-decoration: none;
  font-weight: bold;
  border-radius: 9px;
  box-shadow: 3px 5px 7px 0px gray;
  border: #181818;
  display: block;
}
.link-of-rd-container:hover {
  background-color: #f8c301;
  color: #181818;
  border: #e1b209;
}

.rd-sub-heading {
  text-align: center;
  color: white;
  margin: 2% auto;
  padding: 6px 0;
  background: linear-gradient(45deg, black, transparent);
  background: radial-gradient(
    circle at center,
    #000000 0%,
    #858585 50%,
    #ffffff 100%
  );
  font-size: 1.4rem;
}

.data-container-of-centers-of-excellence-of-rd {
  background: linear-gradient(180deg, rgb(208, 208, 208), transparent);
}
.data-container-of-centers-of-excellence-of-rd img {
  width: 60%;
  margin: auto;
  display: block;
}
.data-container-of-centers-of-excellence-of-rd p {
  padding: 3%;
  line-height: 26px;
  text-align: justify;
  width: 80%;
  display: block;
  margin: auto;
  font-size: 1rem;
}
.sub-heading-of-centers-of-excellence-of-rd {
  text-align: center;
  color: white;
  margin: 2% auto;
  padding: 6px 0;
  background: gray;
  /* background: radial-gradient(
    circle at center,
    #345e0d 0%,
    #69a240 50%,
    #ffffff 100%
  ); */
  font-size: 1rem;
}

/* Responsive css for mobile */
@media only screen and (max-width: 600px) {
  .link-container-of-rd-component ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 3% auto;
    flex-direction: column;
  }
  .link-container-of-rd-component ul li {
    width: auto;
    list-style: none;
    margin: 1% 0;
    text-align: center;
  }
  .data-container-of-centers-of-excellence-of-rd {
    margin: 5% 0;
    border-bottom: 0.3px solid #9a9a9a;
  }
}
