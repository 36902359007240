.examination-about-us-container{
    width: 80%;
    margin: 2% auto;
    font-size: 1.2rem;
    line-height: 28px;
    text-align: justify;
    text-transform: none;
}

.ramchander-khatri-message{
    color: rgb(25, 25, 225);
    font-weight: bold;
    text-align: end;
    text-transform: none;
}

.examination-first-para{
    display: flex;
    justify-content: space-between;
    text-transform: none;
}
.examination-first-para p{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    width: 70% ;
    text-transform: none;
}
.examination-first-para img{
    width: 25% ;
    height: 12%;
}

.examination-team-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    text-transform: none;
}

.examination-team-box{
    width: 30%;
}
.examination-team-img{
    width: 100%;
    height: 80%;
}

.head-of-examination-team{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    width: 80%;
    margin: auto;
    text-transform: none;
}
.head-examination-team-box{
    width: 40%;
    text-transform: none;
}
.head-examination-team-img{
    width: 100%;
    height: 50%;
    text-transform: none;
}