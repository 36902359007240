*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.research-paper-of-rd-component{
    width: 90%;
    margin:2% auto;
}

.research-paper-data-container{
margin:2% auto;
box-shadow: 1px 1px 7px 5px rgb(195, 194, 194);
border-radius: 8px;
padding: 3%;
}
.research-paper-data-container ol li{
    margin: 2% 0;
    font-weight: bold;
    line-height: 25px;
    background: linear-gradient(0deg, rgb(168, 235, 255), transparent);
    padding: 6px ;
    border-bottom: 1px solid #ededed;
    border-radius: 6px;
    text-align: justify;
}
.research-paper-data-container ol li::marker{
   color: rgb(177, 177, 177);
}
.research-paper-data-container p{
margin: 2% 0;
font-weight: bold;
line-height: 25px;
/* background: linear-gradient(0deg, rgb(224, 224, 224), transparent); */
padding: 6px ;
border-bottom: 1px solid #f0f0f0;
border-radius: 6px;
text-align: justify;

}

.research-paper-data-container p a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0092fa;
    visibility: hidden;
    transition: all 0.8s ease-in-out;
  }

  .research-paper-data-container p a:hover::before {
    visibility: visible;
    width: 100%;

  }

.research-paper-data-container p a, .research-paper-data-container ol li a{
text-transform: lowercase;
padding: 0 3px;
color: rgb(0, 145, 255);
text-align: justify;
position: relative;
}

.research-paper-p-date{
    font-size: 1.7rem;
    margin: 2% 0;
    color: rgb(21, 44, 222);
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}