.subscribed-resources-ul{
    width: 80%;
    margin: 2% auto;
}
.subscribed-resources-ul li, .subscribed-resources-ul p{
    margin: 1.5% auto;
    font-size: 1.2rem;
}
.subscribed-resources-ul li a, .subscribed-resources-ul p a{
  font-weight: bold;
  /* color: red; */
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all .3s ease;
}
.subscribed-resources-ul li a:hover, .subscribed-resources-ul p a:hover{
  color: rgb(255, 0, 0);
 
}
.online-newspaper-heading-secondary{
  
  /* text-align: center; */
  margin: 0 7%;
  font-size: 2rem;
  color: rgb(255, 0, 0);
  padding: 2px 0;
  transition: all .4s ease;
  width: fit-content;
  letter-spacing: 2px;
  
}
.online-newspaper-heading-secondary:hover{
  text-shadow: 0 0 5px black;
  cursor:none;
  color: rgb(255, 255, 255);
}


.need-help-question-heading{
  margin: 2% 0;
  color: red;
  text-align: center;
}