* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.questionPapers-component {
    width: 80%;
    margin: auto;
}

.questionPaper-sub-heading {
    text-align: center;
    font-size: 1.7rem;
    margin: 6% 0;
    margin-top: 33px;
    /* background-color: #2b4398; */
    padding: 3px 0;
    color: #761919;
    width: 60%;
    box-shadow: 4px 5px 5px #3f3e18;
}

.questionPaper-content-container {
    background: linear-gradient(180deg, transparent, rgb(223, 223, 231));
    width: 60%;
    border-bottom: 2px solid rgba(180, 179, 179, 0.58);

    margin: 2% auto;
}

.questionPaper-Container-heading {
    text-align: center;
    font-size: 1.5rem;
    margin: auto;
    margin-top: 33px;
    /* background-color: #3f61dc; */
    padding: 3px 0;
    color: #c62332;
    width: 60%;
    box-shadow: 4px 5px 5px #3f3e18;
}

.questionPaper-btn {
    width: 80%;
    margin: auto;
    padding: 2px 0;
    display: block;
    font-size: 1.2rem;
    margin: auto;
    margin-bottom: 0;
    margin-top: 2%;
    color: white;
    background: #086bae;
    box-shadow: 2px 3px 4px 1px grey;
    font-weight: bold;
    font-family: cursive;
}

.questionPaper-onClick-container {
    padding: 7px 17px;
}

.questionPaper-onClick-container-2 {
    border: 2px solid rgb(237, 233, 233);
}

.questionPaper-onClick-container ul li {
    padding: 4px 0;
}

.questionPaper-onClick-container ul li a {
    font-size: 1.2rem;
    color: #000;
    text-decoration: none;
    padding: 2px 4px;
    border-radius: 4px;
}

.questionPaper-onClick-container ul li a:hover {
    background-image: linear-gradient(180deg, transparent, rgb(180, 180, 180));
    /* text-decoration: underline; */
    border-bottom: 2px solid gray;
}


/* Responsive component */

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .questionPapers-component {
        width: 90%;
        margin: auto;
    }

    .questionPaper-content-container {
        width: 98%;
    }

    .questionPaper-Container-heading {
        font-size: 1.2rem;
        width: 83%;
    }

    .questionPaper-onClick-container ul li a {
        font-size: 1.1rem;
    }
}