@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.G20MahaFoodFestival-component{
    width: 89%;
    margin: auto;
}
.upper-logo-container-of-g20-maha-food-festival {
    display: flex;
    justify-content: space-around;
    margin: 2% 0;
}

.g20-maha-food-festival-heading {
    text-align: center;
    color: #f8c301;
    margin: 2% auto;
    padding: 6px 0;
    background: linear-gradient(45deg, black, transparent);
    background: radial-gradient( circle at center, #000000 0%, #858585 50%, #ffffff 100% );
    font-size: 1.4rem;
}

.g20-maha-food-festival-paragrauph{
    width: 95%;
    margin: 2% auto;
    font-size: 1.2rem;
    line-height: 34px;
    /* background: linear-gradient(0deg, #f3efef, transparent); */
  font-weight: bold;
    padding: 2%;
    text-align: justify;
    border-radius: 11px;
    font-family: 'Poppins', sans-serif;

}


.galery-heading-of-g-20-maha-food-f {
    border-radius: 9px;
    font-size: 2rem;
    background: linear-gradient(45deg, transparent, #fe0a0a, transparent);
    width: 88%;
    margin: 2% auto;
    text-align: center;
    color: white;
    letter-spacing: 3px;
}
.image-container-of-g-20-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: baseline;
    align-content: center;
    margin: 2% auto;
}
.image-container-of-g-20-gallery img{
    width: 30%;
    height: 40%;
}

/* Media query for mobile */
@media only screen and (max-width: 600px){
    .upper-logo-container-of-g20-maha-food-festival {
        flex-direction: column;
    }
    .upper-logo-container-of-g20-maha-food-festival img{
       width: 49%;
       margin:2% auto;
}

.g20-maha-food-festival-heading {
    text-align: center;
    color: #f8c301;
    margin: 2% auto;
    padding: 6px 0;
    background: linear-gradient(45deg, black, transparent);
    background: radial-gradient( circle at center, #000000 0%, #4d4d4d 50%, #858585 100% );
    font-size: 1.1rem;
    border-radius: 7px;
}
.g20-maha-food-festival-paragrauph {
    width: 90%;
    font-size: 1rem;
   
}
}