.doctroalProgrammes-data-container{
    width: 80%;
    margin: auto;
}
.doctroalProgrammes-data-container p{
    font-size: 1.2rem;
    line-height: 25px;
    margin: 1%;
}
.doctroalProgrammes-data-container ul{
   width: 70%;
   margin: auto;
   margin-bottom: 2%;
}
.doctroalProgrammes-data-container ul li{
    font-size: 1.2rem;
    line-height: 25px;
    margin: 1%;
}
