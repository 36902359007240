*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
.strategic-plan-of-dpsru-component{
    width: 80%;
    margin: auto;
}

.strategic-plan-dpsru-heading {
    text-align: center;
    color: #ffca07;
    margin: 3% auto;
    padding: 6px 0;
    background: linear-gradient(45deg, black, transparent);
    background: radial-gradient(circle at center, #000000 0%, #858585 50%, #ffffff 100%);
    font-size: 1.4rem;
}
.straqtegic-plan-dpsru-image-container{
    text-align: center;
width: 80%;
    margin: auto;
    margin-bottom: 5%;
}
.straqtegic-plan-dpsru-image-container img{
   width:60%;
   transition: all 1s ease-in-out;

}
.straqtegic-plan-dpsru-image-container img:hover{
   width: 100%;
   box-shadow: 20px 20px 60px #d6d6d6,
   -20px -20px 60px #ffffff;
   

}
