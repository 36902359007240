*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* color: #000; */
}

.notifications-container{
    margin: auto;
    width: 80%;
}

.notifications-textwidget{
    width: 82%;
    margin: 6% auto;
    /* position: relative; */
    /* left: 4%; */
}

.notification-heading {
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: blue;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
}
.notifications-ul{
    list-style-type: circle;
}
.notifications-textwidget ul li{
   margin:2% 0;
   background-color: rgb(250, 250, 250);
   padding: .5%;
   border-radius: 8px;
}
.notifications-textwidget ul li a{
 color: #000;
 text-decoration: none;
   font-size: 1.1rem;
   text-transform: none;
}
.notifications-textwidget ul li a:hover{
  color: red;

}

.span-new{
    color: rgb(254, 196, 196);
    /* background-color: black; */
    padding: 1px 2px;
    transition: all 0.5s ease-in-out;
}
.span-new:hover{
    color: green;
}

.read-more-btn {
    background-color: #181818;
    color: #e1b209;
    padding: 9px 13px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 9px;
    box-shadow: 3px 5px 7px 0px gray;
    border: #181818;
    transition: all .2s ease;
    position: relative;
    left: 76%;
    border-radius: 8px;
  
}
.read-more-btn:hover {
   box-shadow: 4px 4px 6px 0px rgb(118 118 118);
   color: #181818;
   background-color: #e1b209;
}


/* Media query responsive */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .notifications-container {
        margin: auto;
        width: 92%;
    }
    .notifications-textwidget {
        width: 87%;
        margin: 6% 0;
        margin-left: 9%;
        /* left: 4%; */
    }
    .notifications-textwidget ul li a {
        padding: 5px 10px;
        text-decoration: none;
        font-size: 1.1rem;
    }
    .read-more-btn {
        background-color: #111141;
        font-size: 1.2rem;
        border: none;
        position: relative;
        left: 55%;
    }
    .notification-heading {
        padding: 2px;
        color: white;
        background-color: #16162e;
        font-size: 2rem;
        text-align: center;
        box-shadow: 2px 2px 5px grey;
    }
}