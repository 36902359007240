* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iaqc-introduction-component {
    width: 80%;
    margin: auto;
}


.iaqc-intro-heading {
    padding: 6px;
    color: white;
    background-color: blue;
    font-size: 1.7rem;
    text-align: center;
    margin: 3% 0;
    box-shadow: 4px 5px 4px 3px grey;

}
.iaqc-intro-sub-heading {
    padding: 6px;
    color: white;
    background-color: #104376;
    font-size: 1.4rem;
    text-align: center;
    margin: 3% auto;
    box-shadow: 2px 3px 8px 1px grey;
    width: auto;
    width: fit-content;
    padding: 6px 26px;
}


.iaqc-intro-logo-container {
    text-align: center;
}

.iaqc-intro-logo-container img {
    width: 17%;
    box-shadow: 2px 2px 9px 4px grey;
}

.iaqc-intro-text-box p{
        font-size: 1.2rem;
        font-family: ui-sans-serif;
        line-height: 32px;
        text-align: justify; 
}
.iaqc-intro-text-box ul{
        font-size: 1.2rem;
        font-family: ui-sans-serif;
        line-height: 32px;
        margin-bottom: 3%;
}
.iaqc-intro-text-box ul li{
        margin: 1% 0;
}

/* Responsive size for mobile */
@media only screen and (max-width: 600px) {
    .iaqc-intro-logo-container img {
        width: 51%;
        box-shadow: 2px 2px 9px 4px grey;
    }
    .iaqc-intro-text-box ul li{
        margin: 4% 0;
}
}