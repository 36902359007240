.pdf-broucher-view-box{
    width: 100%;
    text-align: center;
}
.pdf-broucher-view-box iframe{
    width: 80%;
    margin: auto;
    height: 600px;
}
.youtube-media-covarage-container{
    display: flex;
    width: 100%;
}
.youtube-media-covarage-box{
    width: 30%;
    margin: auto;
    height: 35vh;
}
.youtube-media-covarage-box a{
   width: 100%;
   height: 100%;
}
.youtube-media-covarage-box a img{
   width: 100%;
   height: 100%;
   transition: all .3s ease-in;
}
.youtube-media-covarage-box a img:hover{
  opacity: .7;
}
.youtube-media-covarage-box p{
  font-size: .8rem;
  font-weight: bold;
  text-align: center;
}
.artical-drug-today a{
margin: 2% auto;
color: red;
font-weight: bold;
text-decoration: none;
}
.artical-drug-today {
padding: 2% 0;
text-align: center;
}
.inner-gallery-text-of-ic{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .youtube-media-covarage-container {
        display: flex
    ;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .youtube-media-covarage-box {
        width: 90%;
        padding:9px 0;
        margin: auto;
        height: 37vh;
    }
}