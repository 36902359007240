*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}
.organogram-component{
    width: 90%;
    margin: auto;
}

.organoram-container-image{
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto;
}
.organoram-container-image a{
    width: 95%;
    display: flex;
    margin: auto;
}
.organoram-container-image a img{
    width: 95%;
    display: flex;
}
.organoram-container-image a img:hover{
    box-shadow: 2px 2px 7px 2px rgb(163, 152, 223);
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .organoram-container-image {
        width: 95%;
        display: flex;
        justify-content: space-evenly;
        margin: 2% auto;
        flex-direction: column;
    }
    .organoram-container-image a {
        width: 95%;
        display: flex;
        border: 1px solid #a7d3b0;
        margin: 4% auto;
    }
    .organoram-container-image a img {
      margin: auto;
    }
}
