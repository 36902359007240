*{
   margin: 0;
   padding: 0;
   text-transform: none;
}
.authority-of-university-g-c-container{
    line-height: 39px;
    width: 80%;
    margin: auto;
    text-transform: none;
}
.authority-of-university-g-c-container li{
   font-size: 1.3rem;
   margin: 1% 0;
   padding: 0 8px;
   background-color: rgba(240, 240, 240, 0.624);
   text-transform: none;
}
.authority-of-university-g-c-container li ol li{
   font-size: 1.3rem;
   margin: 1% 0;
   background-color: rgba(208, 208, 208, 0.624);
   text-transform: none;
}
.authority-of-university-g-c-container li span{
  color: red;
  text-transform: none;
}