.academic-unit-component{
    width: 90%;
    margin: 0% auto;
}
.academic-unit-main-heading{
    letter-spacing: 1px;
    font-size: 2rem;
}

.academic-unit-first-container-of-data{
   width: 80%;
   margin: auto;

}
.academic-unit-first-container-of-data p{
  font-size: 1.3rem;
  margin: 1% 0;
  color: rgb(255, 68, 68);
}
.academic-unit-first-container-of-data ul li{
    margin: 2% auto;
    font-size: 1.3rem;

}