* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.examination-component {
    width: 90%;
    margin: auto;
}

.examination-heading {
    text-align: center;
    font-size: 2rem;
    margin: 10px;
    margin-top: 33px;
    background-color: #15214e;
    padding: 3px 0;
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
    text-transform: none;
}

.examination-carousel-container {
    width: 60%;
    margin: auto;
    text-transform: none;
}

.examination-carousel-container img {
    width: 100%;
    margin: auto;
}

.examination-notification-btn-open {
    color: red;
    font-size: 2.5rem;
    background: none;
    border: none;
    outline: none;
    display: block;
    margin: 2% auto;
    font-weight: bold;
    padding: 3px 12px;
    border-radius: 6px;
    text-transform: none;

}

.examination-notification-btn-close {
    color: green;
    font-size: 2.5rem;
    background: none;
    border: none;
    outline: none;
    display: block;
    margin: 2% auto;
    font-weight: bold;
    padding: 3px 12px;
    border-radius: 6px;
    text-transform: none;

}

.examination-notification-btn-open:hover {
    color: rgb(248, 24, 24);
    box-shadow: 2px 2px 4px 4px gray;
}

.examination-notification-btn-close:hover {
    color: rgb(47, 131, 29);
    box-shadow: 2px 2px 4px 4px gray;
}

.Examination-notification-toggled-container {
    width: 100%;
    margin: auto;
    box-shadow: 2px 2px 3px 4px rgb(210, 210, 210);
    text-transform: none;
}

.Examination-notification-toggled-container-heading h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #222;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    padding: 2% 0;
    text-transform: none;
}

.Examination-notification-toggled-container-heading h1:after,
.Examination-notification-toggled-container-heading h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #003366;
    border-top: 1px solid #003366;
    height: 5px;
    background-color: #f8f8f8;
}

.Examination-notification-toggled-container table tr td a {
    color: black;
    text-decoration: none;
}

.examinataion-navigation {
    /* background-color: #ffe993; */
    font-size: 1.2rem;
    margin: 2% 0;
    box-shadow: 4px 5px 5px #3f3e18;
    font-size: 44px;
}

.examination-navbar {
    margin: auto;
}
.Examination-navigation-hide-on-big-screen {
    display: none;
    text-transform: none;
}

/* Responsive Media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .Examination-navigation-hide-on-small-screen {
        display: none;
    }
    .Examination-navigation-hide-on-big-screen {
        display: flex;
        flex-direction: column;
        margin: 2% 0;
        /* position: relative; */
        /* z-index: -1; */
    }

    .examination-navbar {
        margin: auto;
        width: 100%;
       
    }

    .responsive-navigation-examination-wing-button{
        background-color: #bd9700;
        color: white;
        padding: 2px 0;
        border-radius: 8px;
        font-size: 1rem;
        font-family: cursive;
        font-weight: bold;
        width: 90%;
        margin: auto;
    }
    .responsive-navigation-examination-wing-button:hover{
        background-color: #d9ae01;
        color: white;
       
    }
}