*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.committees-component{
    width: 90%;
    margin: auto;
}

.anti-ragging-nodel-officer-container{
    text-align: center;
    line-height: 22px;
}

/* table */
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin: auto;
  }
  
  td, th {
    border: 1px solid #9e9d9d;
    text-align: left;
    padding: 8px;
    font-size: 1.2rem;
  }
  
  tr:nth-child(odd) {
    /* background-color: #2228340a; */
  }
  /* tr:nth-child(odd):hover {
    background-color: #f7f5f5;
  }
  tr:nth-child(even):hover {
    background-color: #f2eded;
  } */
  .regulation-and-notifications-heading{
    text-align: center;
    text-decoration: underline;
  }
  .regulation-and-notifications-ul {
    margin: 3% 5%;
    line-height: 30px;
}
  .regulation-and-notifications-ul li a{
  color: black;
  text-decoration: none;
  transition: all .3s ease;
}
  .regulation-and-notifications-ul li a:hover{
  color: rgb(255, 0, 0);
  
}
  @media only screen and (max-width: 600px){
    table {
      width: 96%;
   
  }
  }