* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.eDownload-component {
    width: 80%;
    margin: auto;
}

.eDownload-link-container {
    width: 70%;
    margin:auto;
    /* box-shadow: 2px 2px 4px 4px rgb(172, 172, 172); */
    padding: 2%;
    /* background-color: rgb(253, 251, 251); */
    line-height: 35px;
}

.eDownload-link-container ul li {
    margin: 3% 0;
    /* text-align: center; */
    font-size: 1.3rem;
    color: black;
}
.eDownload-link-container ul li p{
    color: black;
}
.eDownload-link-container ul li p a{
    text-decoration: none;
    border-radius: 3px;
    padding: 3px 9px;
    color: black;
    /* border: 1px solid red; */
    /* background-image: linear-gradient(180deg,transparent,rgb(240, 235, 235)); */
    border-bottom: 1px solid gray;
    font-weight: bold;

}
.link-of-e-download{
    text-decoration: none;
    border-radius: 3px;
    padding: 3px 9px;
    color: black;
    /* background-color: rgb(158, 152, 237); */
    background:linear-gradient(180deg,transparent,rgb(158, 152, 237));
    /* border: 1px solid black; */

}
.eDownload-link-container ul li p a:hover{
    background-color: gray;
    color: white;
}


/* responsive Media query */

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .eDownload-component {
        width: 95%;
        margin: auto;
    }
    .eDownload-link-container {
        width: 88%;
        margin: auto;
        box-shadow: 2px 2px 4px 4px rgb(172 172 172);
    }
    .eDownload-link-container ul li {
        margin: 3% 0;
        /* text-align: center; */
        font-size: 1.3rem;
        color: black;
        font-size: 1rem;
        margin-left: 11%;
    }
}
