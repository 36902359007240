*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.results-component{
    width: 80%;
    margin: auto;
}

.results-data-link-container{
    width: 90%;
    margin:4% auto;
}
.results-data-link-container ul li{
    margin: 1.5% 0;
    text-transform: none;
}
.results-data-link-container ul li a {
    font-size: 1.2rem;
    line-height: 30px;
    color: black;
    text-decoration: none;
    /* background: linear-gradient(180deg,transparent,rgb(228, 226, 226) ); */
    padding: 4px 12px;
    border-radius: 4px;
    /* border-bottom: 1px solid rgb(201, 199, 199); */
    transition: all .3s ease;
    text-transform: none;
}
.results-data-link-container ul li a:hover{
  color: red;
}



/* Media query css */

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .results-component {
        width: 91%;
        margin: auto;
    }
    .results-data-link-container ul li {
        margin: 6.5% 0;
        /* border: 1px solid #bfbfbf; */
        margin-left: 7%;
        padding: 3%;
        /* background-color: rgb(193 192 203 / 26%); */
        /* backdrop-filter: blur(0px); */
        box-shadow: 0px 0px 5px 1px gray;
    }
    .results-data-link-container ul li a {
        font-size: 1.1rem;
        background: none;
        border-bottom: none;
    }
}