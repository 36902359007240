.tlp-reports-section-data a{
    display: block;
    text-decoration: none;
    margin: 2% auto;
  }

  /* HIV AIDS Preventions & Control */
  .hiv-aids-control-section {
    width: 80%;
    margin:2% auto;
    line-height: 33px;
  }

  .hiv-aids-control-section ul li a{
 font-size: 1.3rem;
 color: black;
 text-decoration: none;
 
  }
  .hiv-aids-control-section ul li a:hover{
color: red;
  }

  .key-of-hiv-aids-details-off{
    font-size: 1.2rem;
    font-weight: bold;
    color: red;
  }
  .value-of-hiv-aids-details-off{
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
  }
  .value-of-hiv-aids-details-off-email{
   text-transform: lowercase;
  }

  .link-for-online-complain-form-hiv-aids{
    font-size: 1.2rem;
    text-align: center;
    margin: 2% 0;
  }