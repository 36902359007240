
.navigation-container-admin ul{
    display: flex;
    margin: 1% auto;
    padding: 1% 0;
    justify-content: right;
    box-shadow: 0 4px 32px rgb(83 83 83 / 69%);
    width: 97%;
    
}
.navigation-container-admin ul li{
    margin: 1px 2%;
    list-style: none;
}
.navlink-of-admin-pannel-dpsru{
    font-size: 1.3rem;
    text-decoration: none;
    font-weight: bold;
    color: rgb(14, 25, 35);
    transition: all .4s ease;
   font-family: 'Times New Roman', Times, serif;
}
.navlink-of-admin-pannel-dpsru:hover{
    color: #18d9ff;;
}

.logged-person-name-admin-dpsru{
    color:  #ff08d3;
    position: absolute;
    left: 100px;
    font-size: 1.2rem;
    /* background: radial-gradient(0deg,transparent, #787878, transparent); */
    padding: 2px 12px;
    border-radius: 10px;
    /* background: radial-gradient(#9c9c9c, transparent); */
}