* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   text-transform: capitalize;
}

.ucps-component {
   width: 100%;
   margin: auto;
   /* background-color: black; */

}

.ucps-component img {
   /* width: 50%; */
   height: 60vh;
   display: block;
   text-align: center;
   margin: auto;
   aspect-ratio: 3/2;
   object-fit: contain;
   mix-blend-mode: color-burn;
   mix-blend-mode: lighten;
   background-color: transparent;
   filter: drop-shadow(2px 8px 4px rgba(60, 98, 46, 0.5));
   border-bottom: 3px solid #b9b3b30f;
   border: none;
   transition: all .6s ease;
}

.ucps-component img:hover {
   /* width: 50%; */
   width: 700px;
}

.ucps-heading {
   margin: 3% 0;
   color: rgb(241, 11, 11);
   text-shadow: 2px 2px 2px black;
   font-size: 3rem;
   text-align: center;
   font-family: cursive;
padding-bottom: 1%;
}

.ucps-heading:hover {
   background-color: rgb(241, 11, 11);
   color: rgb(0, 0, 0);
   transition: all .5s ease;
   text-shadow: 2px 2px 2px rgb(255, 255, 255);
}

/* social media link */
@media only screen and (max-width: 480px) and (min-width: 320px) {
   .ucps-heading {
      margin: 3% 0;
      color: rgb(241, 11, 11);
      text-shadow: 2px 2px 2px black;
      font-size: 1.5rem;
      text-align: center;
      font-family: cursive;
   }
   .ucps-component img {
      width: 81%;
      height: 60vh;
      display: block;
      text-align: center;
      margin: auto;
      aspect-ratio: 3/2;
      object-fit: contain;
      mix-blend-mode: color-burn;
      mix-blend-mode: lighten;
      background-color: transparent;
      filter: drop-shadow(2px 8px 4px rgba(60, 98, 46, 0.5));
      border-bottom: 3px solid #b9b3b30f;
      border: none;
  }
}

