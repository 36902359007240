/* .nav-header{
background-color: #086bae;
font-family: 'Times New Roman', Times, serif;
} */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: none;
}


.upper-header {
    width: 300px;
    /* display: flex;
    background-color: #086bae; */
    font-family: 'Times New Roman', Times, serif;
}

.upper-navigation {
    padding-top: 12px;
    width: 109%;
    line-height: 39px;
}


.upper-navigation-ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    text-transform: none;
    /* background-color: blue; */

}

.social-section {
    /* padding: 19px; */
    margin-left: 59px;
    margin-top: 1%;
    width: 33%;

}

.socail-link {
    display: inline-block;
    margin: 2px 0px;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 2px 8px;
    margin-bottom: 12px;
}

.navlink {
    padding: 3px 13px;
    list-style: none;
    text-decoration: none;
    margin-top: 20px;
    color: white;
    font-size: 1.2rem;
    text-transform: none;
}

.li-navigation {
    list-style: none;
    text-decoration: none;
    text-transform: none;

}

.seprator {
    list-style: none;
    margin-top: 3px;
    color: white
}

.tiranga img {
    width: 43%;
    position: relative;
    left: 74px;
    top: 25px;
}

/* Middle header section */
.middle-header {
    background-color: #003366;
  
    /* display: flex; */
}


.first-img-section {
    /* position: relative;
    left: 7%; */
    padding-left: 33px;
    max-width: 33%;
}


.first-img {
    width: 24%;
    padding: 7px;
    
}

.upper-header-with-logo {
    display: flex;
    background: url('../Logo/letest-bg1 hd resolution.png') no-repeat center center/cover;
}

.middle-img-section {
    /* text-align: center; */
    width: 15%;
    /* width: fit-content; */
    color: white;
    padding: 12px;
    display: flex;

}

.middle-img {
    width: 100%;
}

.last-img-section {
    max-width: 20%;
}

.middle-txt-section {
    margin: auto;
    width: 60%;
    text-align: center;
    color: white;

}

.middle-img-heading-text {
    /* margin: 0 auto; */
    font-size: 1.1rem;
    text-shadow: 4px 4px 2px #020900;
}

.middle-img-big-txt {
    font-size: 1.6rem;
    font-family: 'Times New Roman', Times, serif;
    margin: 12px 0;
    text-shadow: 4px 4px 2px #020900;
    /* text-shadow: 5px 3px 3px #333; */
}

.middle-img-heading-text-bottom {
    font-size: 1.1rem;
    text-shadow: 4px 4px 2px #020900;
}

.last-image-header-crousel {
    width: 15%;
    padding: 12px;
}

.last-image-header-crousel img {
    width: 100%;
}


.last-img {
    width: 22%;
    padding: 7px;
}

/* Bottom menu */

.bottom-nav {
    top: 0;
    left: 0;
    right: 0;
    /* background-color: #f0c000;
    background-color: rgb(240 232 230); */
    /* background: radial-gradient(circle at center, rgb(236, 231, 230) 0%, #f4f417 50%, #16fc16 100%); */

    /* background: linear-gradient(0deg,#4c4c4c, rgb(55 54 54),#4c4c4c); */
    background-color: #181818;
    background-color: #f0c000;
    padding: 0 0%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    z-index: 1000;
    

}

.navbar {
    position: relative;
    /* left: 1%; */
    margin: auto;
    /* display: flex;
    flex-wrap: wrap; */
    /* overflow: auto; */
  /* white-space: nowrap; */
  text-transform: none;
    
}

.bottom-nav .navbar ul {
    list-style: none;

}

.bottom-nav .navbar ul li {
    position: relative;
    float: left;
    text-transform: none;
}

/* search bar */


.search-bar-box {
    padding: 1px;
    background: #f3c103;
    margin-top: 5%;
    border-radius: 5px;
}


#search-bar-nav {
    position: relative;
    top: -0.5px;
    border: none;
    outline: none;
    padding: 4px;
    border-radius: 5px;
}

.search-bar-button {
    background: none;
    color: rgb(0, 0, 0);
    border: none;
    padding: 0 4px;
}
.search-bar-button svg {
        position: relative;
        top: 3px;
}
.search-bar-button:hover {
    color: rgb(255, 0, 0);
    cursor: pointer;
}

/* search bar */


.bottom-nav .navbar ul li .bottom-menu-navLink-first {
    font-size: 17px;
    padding: 14px;
    color: var(--fontColor);
    color: rgb(0, 0, 0);
    /* background: linear-gradient(0deg,#4c4c4c, rgb(55 54 54),#4c4c4c); */
    background-color: #181818;
    background-color: #f0c000;
    display: block;
    text-decoration: none;
    text-transform: none;
    /* font-weight: bold; */


}


.bottom-nav .navbar ul li .bottom-menu-navLink-first:hover  {

    background-image: linear-gradient(to bottom, #f8c301 0, #c99e00 100%);
    
    background-image: linear-gradient(to bottom, #0b6dcf 0, rgb(1, 80, 176) 100%);
    color: #ffffff;

    /* background-color: #f8c301;
    color: #181818;
    border: #e1b209; */

}

.bottom-menu-navLink-first-home-icon{

border-radius: 50%;
}


/* godown */
@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

/* godown */

.bottom-nav .navbar ul li ul {
    position: absolute;
    left: 0;
    width: 150px;
    background-color: #fff;
    display: none;
    margin: 0;
    /* width: fit-content; */
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    text-transform: none;
}


.bottom-nav .navbar ul li ul li {
    width: 100%;
    /* border-top: 1px solid gray; */
    /* border: .5px solid yellow; */
    background-color: #fff;
    color: #000;
    z-index: 999;
    text-transform: none;
}

.bottom-nav .navbar ul li ul li ul {
    left: 150px;
    top: 0;
    

}

/* .bottom-nav .navbar ul li:focus-within>ul, */   
/* upper line has been commented for after click on any navigation it will going to stuck thats is why i commentded this line and now still working fine date 11-05-2023 */
.bottom-nav .navbar ul li:hover>ul {
    display: initial;
    /* animation: growDown 600ms ease-in-out forwards; */
    animation: growDown 600ms ease-in-out forwards;
    transform-origin: top center;
    z-index: 999;
}



.search-bar-nav input {
    margin-top: 28%;
    border-radius: 15px;
    padding: 3px;
    margin-right: 18px;
    outline: none;
    border: none;

}

/* Seeting inner navigation size */
.bottom-nav .navbar ul li ul li .inner-li-of-school-inside-of-academics {
    width: 250%;
}

/* Seeting inner navigation size */
.bottom-nav .navbar ul li ul li .inner-li-of-school-inside-of-academics-centers {
    width: 200%;
}


/* Responsive display blockin css  */
.responsive-bottom-navigation {
    display: none;
}

.responsive-navigation-button {
    display: none;
}