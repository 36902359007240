.timeTable-data-container{
   width: 80%;
   margin:4% auto;
}
.timeTable-data-container p , .timeTable-data-container ul li{
    margin: 2% 0;
}

    .timeTable-data-container p img {
        width: 50px;
        position: relative;
        bottom: -10px;
        z-index: 999;
        left: -24px;
        transition: all .3s ease;
}
    .timeTable-data-container p img:hover {
        width: 54px;
}
.timeTable-data-container p a, .timeTable-data-container ul li a {
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 5.5px 22px;
    border-radius: 7px;
    transition: all .6s ease;
}
.timeTable-data-container p a:hover, .timeTable-data-container ul li a:hover  {
    color: rgb(255, 205, 4);
   background-color: rgba(27, 27, 27, 0.972);
}

.time-table-secondary-heading{
    background: #787878;
    color: yellow;
    text-align: center;
    padding: 2px;
    text-decoration: none;
    margin: 3% 0;
}