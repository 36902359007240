@media only screen and (max-width: 600px){
    .administrative-staff {
        width: 78%;
    }

    .elementor-heading-title {
        font-size: 1.3rem;
        padding: 2% 0;
        width: 100%;
        margin: 4% auto;
        background: #222258;
        box-shadow: 2px 2px 5px grey;
    }

    .adninistrative-staff-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        line-height: 24px;
    }
    .staff-each-image-box {
        width: 80%;
        text-align: center;
        margin: 2% auto;
    }
    .staff-each-image-box-secondary-box-small {
        width: 47%;
        text-align: center;
        margin: 3% auto;
    }
}