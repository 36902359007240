*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.aboutUs-component{
    width: 80%;
    margin:4% auto;
}

.aboutUs-heading {
    background-color: #ffffff;
    box-shadow: 3px 3px 3px 1px #e7e7e7;
    color: #161665;
    font-size: 2rem;
    padding: 6px;
    text-align: center;
    text-shadow: 2px 2px 2px #9c9a9a;
}

.aboutUs-second-heading {
  
    text-align: center;
    color: white;
    margin: 3% auto;
    padding: 6px 0;
    background: linear-gradient(45deg, black, transparent);
    background: radial-gradient(circle at center, #000000 0%, #858585 50%, #ffffff 100%);
    font-size: 1.4rem;
}

.aboutUs-paragrauph{
    padding: 12px;
    text-align: justify;
    line-height: 170%;
    font-size: 1.3rem;
    text-transform: none;
}

.campus-highlight-container-in-about-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.campus-highlight-container-in-about-page figure{
    width: 22%;
    margin: 3% 0;
}
.campus-highlight-container-in-about-page figure figcaption{
    text-align: center;
    font-weight: bold;
}
.campus-highlight-container-in-about-page figure img{
    width: 96%;
    height: 100%;
}
.campus-highlight-container-in-about-page figure img:hover{
   box-shadow: 2px 2px 6px 2px gray;
}

.flex-box-for-side-imoportant-link-about-university{
    display: flex;
}

.flex-box-first-container-main-content-of-page-with-size-70{
    width: 74%;
}

.flex-box-second-container-side-link-content-container-of-page-with-size-30{
    width: 25%;
}

.archive-container-in-about-us-page{
    display: flex;
    justify-content: end;
    margin: 2% auto;
}
.archive-navlink-in-about-us {
    color: rgb(56 51 51);
    font-weight: bold;
    text-decoration: none;
    font-size: 1.1rem;
}
.archive-navlink-in-about-us:hover{
   color: rgb(186, 0, 0);
   font-weight: bold;
   text-decoration: none;
   font-size: 1.1rem;
 
}
.archive-icon-in-aboutus{
    position: relative;
    top: 3.5px;
}




@media only screen and (max-width: 600px) {

    .aboutUs-second-heading {
        width: 80%;
        background-color: rgb(19 19 57);
        margin: 7% auto;
        border-radius: 18px;
    }

    .aboutUs-heading {
        padding: 2px;
        color: white;
        background-color: #161665;
        font-size: 2rem;
        text-align: center;
        box-shadow: 3px 3px 3px 1px grey;
    }
    .aboutUs-paragrauph {
        padding: 12px;
        text-align: justify;
        line-height: 151%;
        font-size: 1.3rem;
        font-size: 1rem;
    }
    .flex-box-first-container-main-content-of-page-with-size-70 {
        width: 70%;
    }
    .flex-box-second-container-side-link-content-container-of-page-with-size-30 {
        width: 29%;
    }
}