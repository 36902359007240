/* Moble phoene start here */
/* @media only screen and (max-width: 480px) and (min-width: 320px) { */
@media only screen and (max-width: 600px){

    .main-bottom-nav-for-hide-in-responsive {
        display: none;
    }

    .bottom-nav .navbar ul li {
        float: none;

    }

    .responsive-navigation-button-container {
        background: #bd9700;
        width: 100%;
        border-bottom: 2px solid #282896;
    }

    .responsive-navigation-button {
        font-size: 2rem;
        display: initial;
        color: white;
        text-align: center;
        background: #bd9700;
        border: none;
        padding: 1px 4px;
        align-items: center;
        display: flex;
    }

    .responsive-navigation-button:hover {
        color: #000;
    }

    .responsive-bottom-navigation {
        display: initial;
    }

    .navbar {
        position: relative;
        /* left: 4%; */
        background: #f0c000;
        background: linear-gradient(162deg, #ffcc00, #81aca5);
        font-weight: bold;
        padding: 2%;
        left: 0;
    }

    .middle-img-section {

        width: 18%;
      
    }
    .last-image-header-crousel {
        width: 18%;
        padding: 12px;
    }
    .search-bar-box {
        padding: 1px;
        background: #f3c103;
        border-radius: 5px;
        width: 80%;
        margin: auto;
        margin: 3% auto;
    }
    #search-bar-nav {
        position: relative;
        top: 0.5px;
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
        width: 85%;
    }
    .search-bar-button svg {
        position: relative;
        top: 3px;
        /* display: block; */
        /* margin: auto; */
        left: 83%;
    }
    .bottom-menu-navLink-first-home-icon {
        border-radius: 0%;
        text-align: center;
        font-size: 2rem;
    }
}

/* Moble phoene end here */


/* for tablet */
@media only screen and (max-width: 1000px) and (min-width: 601px) 
{
    .navbar {
        position: relative;
        left: 0%;
    }
}