.library-section-each{
    margin: .5% 0;
}

.library-navlink{
    display: block;
    text-decoration: none;
}
.library-paraqgrauph-about-us {
    font-size: 1.2rem;
    line-height: 33px;
    width: 82%;
    margin: auto;
    text-align: justify;
    font-family: ui-serif;
}
.library-timing-heading{
    font-weight: bold;
    margin: auto 0;
    color: red;
}

.library-table-online-mode{
    width: 90%;
    font-size: 1.2rem;

}
.library-table-online-mode td{
  text-align: center;
}
.library-table-online-mode tr td a span{
 text-transform: lowercase;
 color: rgb(0, 0, 0);
 font-weight: bold;
 text-decoration: underline;
 transition: all .2s ease;
}
.library-table-online-mode tr td a span:hover{
 color: rgb(255, 0, 0);
}

.dpsru-library-ul{
    margin: 0 5%;
}

.dpsru-library-ul li{
 margin: 1% 0;

 font-size: 1.2rem;
}

.img-container-library img{
    margin: 1% auto;
   transition: all .4s ease;
}
.img-container-library img:hover{
    transform:scale(1.2);
    box-shadow: 1px 1px 7px 3px rgb(0, 0, 0);
}