@import url('https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap');
.dpsru-login-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin:2% auto;
    text-align: center;
    justify-content: center;
    padding: 3%;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.05);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(3.8px);
-webkit-backdrop-filter: blur(3.8px);
border: 1px solid rgba(255, 255, 255, 0.24);
transition: all .5s ease;
}

.input-box-each-container-login{
    margin: 1% auto;
}
.input-box-each-container-login label{
  display: block;
  color: white;
}
.input-box-each-container-login input{
    padding: 4px;
    outline: none;
    border: none;
    border-radius: 3px;
    text-transform: lowercase;
    background: none;
    color: rgb(54, 185, 246);
    border-bottom: 1.5px solid rgb(0 223 253);
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Yuji Boku', serif;
    
}

.login-btn-for-dpsru {
    width: fit-content;
    padding: 3px 12px;
    
    font-weight: bold;
  
    border: none;
    outline: none;
    margin: 2% auto;
    border-radius: 4px;
    transition: all .4s ease;
    font-size: 1.1rem;
    color: #01d2fb;;
    background: #00000000;
    border: 2px solid #01d2fb;;
   
}
.login-btn-for-dpsru:hover{
    color: black;
    background: #01d2fb;
    border: 2px solid #01d2fb;
}


.dont-have-account-text{
    color: white;
    margin: 1% 0;
}

.signup-button-in-login-page{
    color: #f3c107;
    font-size: 1.3rem;
    margin: 1%;
    text-decoration: none;
    font-weight: bold;
}
.signup-button-in-login-page:hover{
    color: #ff170b;
}
.login-admin-dpsru-password-inpbox{
    display: inline;
    position: relative;
    left: 8px;
}
.login-heading{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.05);
border-radius: 6px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(3.8px);
-webkit-backdrop-filter: blur(3.8px);
border: 1px solid rgba(255, 255, 255, 0.24);
box-shadow: 0 4px 32px rgb(83 83 83 / 69%);
color: #01d2fb;
}
.show-hide-button {
    background: none;
    outline: none;
    border: none;
    color: #01d1fbb3;;
    font-size: 1.2rem;
    position: relative;
    left: -15px;
    top: 7px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all .3s ease;
}
.show-hide-button:hover {
   color: #01d2fb;;
   cursor: pointer;
}

/* btn css */
.btn-login-dpsru {
    perspective: 1000px;
    border: none;
    outline: none;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-shadow: 0 10px 10px #000;
    cursor: pointer;
    transform: rotateX(70deg) rotateZ(0deg);
    transform-style: preserve-3d;
    transition: transform 0.5s;
    color: #01d2fb;;
    background: none;
    border: 2px solid #01d2fb;
    width: 36%;
    margin:2.5% auto;
    padding: 1.5% 0;
  }
  
  .btn-login-dpsru::before {
    content: "";
    width: 100%;
    height: 15px;
    background-color: rgb(25 205 229);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotateX(90deg);
    transform-origin: bottom;
    color: #01d2fb;;
  }
  
  .btn-login-dpsru::after {
    content: "";
    width: 15px;
    height: 100%;
    background-color: rgb(0 223 253);
    position: absolute;
    top: 0;
    right: 0;
    transform: rotateY(-90deg);
    transform-origin: right;
  }
  
  .btn-login-dpsru:hover {
    transform: rotateX(30deg) rotateZ(0);
    color: black;
    background: #01d2fb;
    border: 2px solid #01d2fb;
  }
  .icon-for-login-user-password {
    color: #ff9999;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: -3px;
    right: -7%;
}
  .icon-for-login-user{
    right: -9.7%;
}

/* For Mobile Screen */
@media only screen and (max-width: 600px){
    .dpsru-login-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5% auto;
        text-align: center;
        justify-content: center;
        /* background-color: black; */
        padding: 3%;
    }
}