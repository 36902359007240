* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.testing-tabs-component {
  width: 97%;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 5%;
}

.testing-tab-container-of-full-content {
  /* border: 1px solid black; */
  padding: 30px;
  border-radius: 17px;
  background: #efefef;
  box-shadow: -5px 3px 60px #bebebe;
  /* background: linear-gradient(
      170deg,
      rgba(49, 57, 73, 0.8) 20%,
      rgba(49, 57, 73, 0.5) 20%,
      rgba(49, 57, 73, 0.5) 35%,
      rgba(41, 48, 61, 0.6) 35%,
      rgba(41, 48, 61, 0.8) 45%,
      rgba(31, 36, 46, 0.5) 45%,
      rgba(31, 36, 46, 0.8) 75%,
      rgba(49, 57, 73, 0.5) 75%
    ),
    linear-gradient(
        45deg,
        rgba(20, 24, 31, 0.8) 0%,
        rgba(41, 48, 61, 0.8) 50%,
        rgba(82, 95, 122, 0.8) 50%,
        rgba(133, 146, 173, 0.8) 100%
      )
      #313949; */
}

.testing-tab-heading {
  text-align: center;
  font-size: 2rem;
  margin: 2%;
}

.button-box-of-tab {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 16px; */
}

.tabsCompBtn {
  width: 33.33333333%;
  padding: 4px 12px;
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
  border: none;
  background: none;
  border-bottom: 1px solid #c7c7c7;
  /* border-radius: 16px; */
  position: relative;
  top: 0;
transition: all 0.25s;
}
.button-box-of-tab button:hover {
  color: rgb(255, 0, 0);  
  /* background-color: rgb(99, 99, 99); */
  
}
.isActiveTabBtn {
  /* font-weight: bold; */
  color: rgb(0, 0, 0);
  border-top: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  border-bottom: none;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  animation: all 1s ease-in-out;
  background-color: white;
  box-shadow: inset -4px 2px 2px 1px rgba(103, 103, 103, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
  
}

.inner-container-of-tab-button {
  background-color: white;
  padding: 1%;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}

.inner-container-heading-of-tab-button {
  text-align: center;
  font-size: 1.8rem;
  color: rgb(101, 101, 101);
  margin: 1% 0;
}

/* Content */

.inner-content-container-of-tab-home-dpsru {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.first-ul-of-tab-container {
  /* width: 32%; */
  width: 80%;
  margin: auto;
  /* border: 1px solid red; */
  list-style: none;
}
.first-li-of-tab-container {
  background: linear-gradient(45deg, rgb(220, 218, 218), transparent);
  /* border-bottom: 0.5px solid rgb(172, 172, 172); */
  padding: 3px;
  margin: 4% 0;
  border-radius: 4px;
}
.content-li-of-tabs-home{
  width: 70%;
}
.second-ul-of-tab-container {
  display: flex;
  /* border: 1px solid yellow; */
  justify-content: space-between;
  padding: 1%;
  list-style: none;
}
.second-ul-of-tab-container li a {
  color: black;
  text-decoration: none;
}
.second-ul-of-tab-container li a:hover {
  color: rgb(255, 0, 0);
}
.date-li-of-tabs-home {
  color: rgb(82, 82, 247);
}

/* ReadMore button */
/* ReadMore button */
.animated-button {
  background: linear-gradient(-30deg, #0b1b3d 50%, #08142b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#2662d9)
  );
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#2662d9)
  );
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 20, 43, 0)),
    to(#2662d9)
  );
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(8, 20, 43, 0)),
    to(#2662d9)
  );
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  padding: 8px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7efd4;
  font-size: 16px;
  letter-spacing: 2.5px;
  text-align: center;
  /* text-transform: uppercase; */
  text-decoration: none;
  /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
  display: flex;
  width: 13%;
  position: relative;
  right: 60px;
  /* float: right; */
  left: 80%;
  border-radius: 8px;
  text-align: center;
}

.animated-button1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#fba00e)
  );
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #fba00e);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#fba00e)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #fba00e);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(43, 8, 8, 0)),
    to(#fba00e)
  );
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #fba00e);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: -15.9px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(43, 8, 8, 0)),
    to(#fba00e)
  );
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #fba00e);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}


/* ReadMore button end */
/* ReadMore button end */

/* responsive Media query */
/* for mobile */
@media only screen and (max-width: 600px) {
  .testing-tabs-component {
    width: 99%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .tabsCompBtn {
    height: 49px;
    font-size: 0.7rem;
  }
  .inner-container-heading-of-tab-button {
    font-size: 1.3rem;
  }
  .inner-content-container-of-tab-home-dpsru {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
}
.first-ul-of-tab-container {
  width: 90%;
  margin: auto;
}
.animated-button1 {
  padding: 8px 11px;
  margin: 6px;
  font-size: 12px;
  width: 33%;
  position: relative;
  right: 60px;
  /* float: right; */
  left: 60%;
  border-radius: 8px;
  text-align: center;
}
}
