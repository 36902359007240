*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.newsOfDpsru-component{
    width: 80%;
    margin: auto;
}

.newsOfDpsru-container ul{
    width: 80%;
}

.newsOfDpsru-heading {
    text-align: center;
    font-size: 1.4rem;
    margin: 10px;
    margin-top: 33px;
    background-color: rgb(16, 16, 229);
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
}

.newsOfDpsru-container ul li a{
color: black;
text-decoration: none;
}