*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.box-container-iaqc-q-i{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
}

.q-i-data-container {
    width: 45%;
    /* height: 20vh; */
  
}
.inner-container-of-q-i-iaqc{
    background-color: gray;
    background: linear-gradient(0deg, rgb(235, 233, 233), transparent);
}

.q-i-iaqc-button {
    width: 100%;
    padding: 10px 0;
    background: #242471;
    box-shadow: 2px 2px 4px 2px gray;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 4px;
}
.q-i-iaqc-button:hover {
    background: #3c3ca7;
    box-shadow: 3px 3px 5px 3px gray;
  
}

.q-i-data-container-third{
    width: 100%;
    padding: 2%;
}

.q-i-iaqc-button-third{
    width: 100%;
    display: block;
    margin: auto;
}

.inner-container-of-q-i-iaqc ul {
    padding: 3% 4%;
}
.inner-container-of-q-i-iaqc ul li{
  margin: 3% 0;
}
.inner-container-of-q-i-iaqc ul li a{
   color: black;
   text-decoration: none;
   font-size: 1.1rem;
   padding: 6px 16px;
   border-radius: 6px;
   font-weight: bold;

}
.inner-container-of-q-i-iaqc ul li a:hover{
   color: rgb(35, 68, 255);
background: linear-gradient(0deg, rgb(205, 202, 202), transparent);
border-bottom: 1px solid rgb(35, 68, 255);
}

@media only screen and (max-width: 600px) {

    .iaqc-introduction-component {
        width: 90%;
        margin: auto;
    }
    .iaqc-intro-heading {
        font-size: 1.1rem;
    }
    .box-container-iaqc-q-i {
        flex-direction: column;
    }
    .q-i-data-container {
        margin: 3% 0;
        width: auto;
    }
    .q-i-data-container-third {
       padding: 0;
       margin-bottom: 3%;
    }
}