.swachta-hi-seva-paragrauph{
   
        font-size: 1.2rem;
        color: #252565;
        font-weight: bold;
        line-height: 29px;
        width: 100%;
        margin: 2% auto;
        text-align: justify;

}

.swachta-seva-gallery-container{
    display: flex;
}
.swachta-seva-img-box{
    width: 40%;
    margin: auto;
}
.swachta-seva-img-box img{
    width: 100%;
    height: 350px;
}

.medicalLabTechWeek-heading {
    font-size: 2rem;
    width: 80%;
    margin: 2% auto;
    
    text-align: justify;
}