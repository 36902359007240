.academic-units-data-container{
    width: 80%;
    margin: 2% auto;
}
.academic-units-data-container p{
   font-size: 1.2rem;
   margin: 2% 0;
   line-height: 25px;
   text-align: justify;
}
.academic-units-data-container ul{
  width: 70%;
  margin: auto;
}
.academic-units-data-container ul li{
    font-size: 1.2rem;
    margin: 2% 0;
    line-height: 25px;
    text-align: justify;
}