*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iastamOration-component{
    width: 80%;
    margin: auto;
}
.iastamOration-heading{
    text-align: center;
    font-size: 1.4rem;
    margin: 10px;
    margin-top: 33px;
    background-color: rgb(16, 16, 229);
    color: white;
    box-shadow: 4px 5px 5px #3f3e18;
    padding: 4px 0;
}

.iastamOration-data-container ul{
    width: 80%;
    margin:2% auto;
}
.iastamOration-data-container ul li{
    margin: 2% 0;
}
.iastamOration-data-container ul li a{
   color: black;
   text-decoration: none;
   font-size: 1.2rem;
}
.iastamOration-data-container ul li a:hover{
   color: red;
}