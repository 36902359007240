*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.nss-component{
    width: 100%;
}

.nss-container {
    width: 77%;
    line-height: 288%;
    margin: 4% auto;
}


.nss-heading {
    background-color: blue;
    color: white;
    font-size: 2rem;
    text-align: center;
}

.nss-container p a {
    color: #333;
    text-decoration: none;
    font-weight: 900;
}
.nss-container p a:hover {
    color: #000;
    text-decoration:underline;
}


/* Responsive Media query */
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .nss-container {
        width: 90%;
        line-height: 264%;
        margin: 3% auto;
    }
    .nss-container p{
       margin-left: 9%;
       border: 1px solid gray;
       margin: 1% 0;
       padding: 1%;
    }
    .nss-container p a{
      font-size: 1.1rem;
    }
    .nss-heading {
        background-color: #262653;
        color: white;
        font-size: 2rem;
        box-shadow: 2px 2px 4px grey;
        text-align: center;
    }
    
    
}