.event-link-container{
    margin: 1% 0;
}
.evnet-page-ul-container{
    line-height: 38px;
    width: 85%;
    margin: auto;
}
.evnet-page-list{
    /* line-height: 28px; */
}
.event-files-link {
    color: black;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
    transition: all .2s ease;
    margin: 2% 0;
}
.event-files-link:hover {
    color: rgb(255, 5, 5);
}
.event-a-click-here{
    color: red;
    text-decoration: none;
}