

.rti-act-data-container{
    width: 80%;
    margin: 3% auto;
}
.rti-act-data-container ul{
    line-height: 36px;
}
.rti-act-data-container ul li a{
   color: black;
   font-size: 1.2rem;
   font-weight: bold;
   text-decoration: none;
   padding: 5px 20px;
   border-radius: 6px;
   transition: all .3s ease-in-out;
/* text-transform: lowercase; */
}
.rti-act-data-container ul li a:hover{
   color: rgb(254, 8, 0);
   /* background-color: rgb(32, 32, 32); */
}