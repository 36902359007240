*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.nirf-component{
    width: 85%;
    margin: auto;
    margin-bottom: 3%;
   
}

.nirf-heading{
    margin-bottom: 2%;
}

.nirf-data-container{
    display: flex;
   flex-wrap: wrap;
    justify-content: space-around;
    animation: paperRoll .7s ease-in-out;
    transform-origin: top;
    overflow: hidden;
    padding-bottom: 2%;
}

.nirf-data-section-eact {
    width: 30%;
    text-align: center;
   margin: 2% 4%;
}


.nirf-botton {
    width: 100%;
    background-color: #181818;
    color: #e1b209;
    padding: 9px 0;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 9px;
    box-shadow: 3px 5px 7px 0px gray;
    border: #181818;
    transition: all .2s ease;
  
}
.nirf-botton:hover{
    background-color: #f8c301;
    color: #181818;
    border: #e1b209;
}
    .inner-container-of-nirf-data {
        background: linear-gradient(0deg, #edecec, transparent);
        text-align: left;
        padding: 3% 6%;
        border-radius: 11px;
        animation: paperRoll .7s ease-in-out;
  transform-origin: top;
  overflow: hidden;
    }

    @keyframes paperRoll {
        0% {
          transform: scaleY(0);
        }
        80% {
          transform: scaleY(1.1)
      }
        100% {
          transform: scaleY(1);
        }
      }
      
    .inner-container-of-nirf-data ul li {
        list-style: none;
        margin: 6% 0;
    }
    .inner-container-of-nirf-data ul li a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    }
    .inner-container-of-nirf-data ul li a:hover {
   /* background-color: black; */
   color: rgb(255, 0, 0);

    }

    /* Responsive for phone */
    @media only screen and (max-width: 600px){
        .nirf-data-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* width: 100%; */
        }
        .nirf-data-section-eact {
            width: 90%;
            text-align: center;
            margin: 2% 0;
        }
    }